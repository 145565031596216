<template>
    <v-row v-if="loaded" no-gutters justify="center">
        <v-col cols="12" class="ma-0 pa-0 d-flex justify-center text-center">
            <v-card  v-if="!notClicked" class="pa-0 pt-6 justify-center align-end text-center" elevation="0"  min-width="375px" max-width="600px">
                <h2 class="pb-2"> Publish or Comparish Study</h2>
                <p class="pb-2">{{this.resultText + this.promptText[this.stage-1]}}</p>
                <h4 class="text-center">{{this.placeholderTexts[this.stage-1]}}</h4>
                <v-autocomplete justify="center" align="center"
                    v-if="stage<4"
                    class="search pt-0 ma-0 px-0 custom-placeholder-color"
                    id="search"
                    ref="search"
                    v-model="search.journal"
                    :items="search.journals"
                    item-title="name"
                    item-value="id"
                    v-model:search="searchField"
                    return-object
                
                    :clearable="false"
                    :disabled="showStatus"
                    
                    placeholder="Click & Enter Venue Name"
                    :single-line="true"
                    :hide-details="true"
                    :hide-no-data="true"
                    density="comfortable"
                    variant="underlined"
                    prepend-inner-icon="mdi-magnify"
                    color="blue-grey-darken-1"

                    @focus="canCut = false"
                    @blur="canCut = true"
                    @update:modelValue="searchChange()"
                    :menu-props="menuProps"
                    autocomplete="off"
                >
                </v-autocomplete> 
                
                <v-form v-if="stage==4" v-model="refValid" ref="ref" @submit="checkRef" class="mb-0 pb-2">
                    <v-row no-gutters justify="center">
                    <v-col cols="12">
                    <v-btn class="mt-0" 
                    width="250px"
                    height="20px"
                    color="blue"
                    variant="tonal"
                    :disabled="true"
                    @click="checkRef">Continue to Consent Form</v-btn>
                    </v-col>
                    </v-row>
                </v-form>
            </v-card>
        </v-col>

        <v-col cols="12" class="ma-0 pa-0 justify-center text-center">

            <v-card class="mx-0 text-center px-0" variant="text">
                <BonusMapSim
                :simWidth="simWidth"
                :simHeight="simHeight"
                :selected="selected"
                :vizData="vizData"
                :colorDict="colorList"
                :groups="groups"
                :nodeRadius="radius"
                :flipped="flipped"
                :headerHeight="0"
                :notClicked="notClicked"
                :stage="stage - 1"
                :topJournals="topJournals"
                @clicked="clicked"
                />
            </v-card> 
        </v-col>
    </v-row>
</template>

<script>
import axios from 'axios';
import BonusMapSim from '../BonusMapSim.vue'

export default {
        components: {
        BonusMapSim
    },
    data: () => ({
        loaded: false,
        simWidth: 800,
        simHeight: 800 - 160,
        selected: {"name": "", "valid": false},
        vizData: {},
        topJournals: [],
        topJournalNames: [],
        topCounts: [],
        colorList: null,
        colorDict: null,
        groups: ['ku'],
        radius: 1,
        areas: null,
        flipped: false,
        headerHeight: 125,
        notClicked: false,
        email: "",
        rules: [v => !!v || 'Required'],
        refValid: false,
        stage:1,
        search: {
            promptCount: localStorage.nDiscovered,
            journal: null,
            journals: [],
            searchColor: "blue",
            isLoading: false,
            height: localStorage.nDiscovered >= 3 ? "60px" : "40px"
        },
        searchField: null,
        showStatus: false,
        placeholderTexts: ["In your field, what's a top venue you'd like to publish in?",
                          "If you weren't aiming for a top venue, where might you publish?",
                          "If you didn't aim as high as either of these venues, where might you publish?"
                        ],
        menuProps: {maxWidth: "100px"},
        headers: ["In your field...",
                  "If you weren't aiming for a top venue,",
                  "If you didn't aim as high as either of these venues,"],
        promptText: ["In your field, what's a top venue you'd like to publish in? The visualization below shows answers to that question from ~2,000 participants in the Publish or Comparish study. Each circle is a venue, and larger circles are more commonly chosen. See where you land by answering the question below 👇!",
                    "Now, circle sizes indicate how often a venue is chosen when participants aren't aiming for a top venue. See where you land by answering the question below 👇!",
                    "When participants were prompted to select a third venue that they didn't consider as prestigious as the other two, a greater diversity of venues were selected. See where you land by answering the question below 👇!",
                    "You just completed the first three questions of the Publish or Comparish Study (Univ. Colorado IRB no. 23-0454). Nice job! Interested in participating in the full survey? Click here to proceed to the consent form — we won’t save your data otherwise."],
        resultText: "",
        numberText: ["","top","second","third"]
    }),
    watch: {
        searchField(a){
            this.search.isLoading = true
            const path = process.env.VUE_APP_BASE_URL+'/searchjournals';
            if (a !== null && a !== "" && !this.search.journal){
                axios.post(path, {string: a})
                .then((res) => {
                    const new_journals = [];
                    for (let i = 0; i < res.data.length; i++){
                        if (!this.topJournalNames.includes(res.data[i][1])){
                            new_journals.push({"id":res.data[i][0],"disabled": false,
                                    "name":res.data[i][1], "valid":true})
                        }
                }
                this.search.journals = new_journals;
                })
            } else {
                this.search.journals = [];
            }
            this.search.isLoading = false;
        },
    },
    mounted(){
        this.resize()
        window.addEventListener("resize", this.resize);
        this.viewResults()
    },
    methods: {
        resize() {
            const windowWidth = window.innerWidth
            const windowHeight = window.innerHeight - 180
            let ratio
            if (windowHeight > windowWidth){
                ratio = Math.min(windowWidth/974,windowHeight/1792)
                this.simWidth = ratio*974
                this.simHeight = ratio*1792
                this.flipped = true
            }
            else {
                ratio = Math.min(windowWidth/1792,windowHeight/974)
                this.simWidth = ratio*1792 
                this.simHeight = ratio*974
                this.flipped = false
            }
            this.findRadius()
        },
        findRadius(){
            if (this.areas){
                let v = this.simWidth * this.simHeight
                this.radius = Object.entries(this.areas).filter( x => x[1]/v < .35).map(x => x[0]).slice(-1)
            } else {
                this.radius = 1
            }

        },
        async viewResults() {
            const path = process.env.VUE_APP_BASE_URL+'/bonusmap';
            await axios.post(path, null)
            .then((res) => {
                this.vizData = res.data.vizData
                this.colorList =  res.data.color_list
                this.colorDict =  res.data.color_dict
                this.areas = res.data.areas
                this.findRadius()
                this.loaded = true
            })
        },
        clicked (s){
            this.selected = s
        },
        async searchChange(){
            if (this.search.journal !== null){
                this.journal = {'name':this.search.journal.name, 'id':this.search.journal.id, 'valid':true}
                this.submitDiscoverJournal(this.journal)
            }
        },
        async submitDiscoverJournal(journal) {
            const path = process.env.VUE_APP_BASE_URL+'/getjournal';
            const payload = {id: journal.id};
            await axios.post(path, payload)
                .then((res) => {
                    if (res.data.ej.length == 0){
                        this.resultText = "You are the first one to select " + res.data.j[3] + "as their " + this.numberText[this.stage] + " choice."
                        this.topCounts.push(0)
                        this.topJournalNames.push(res.data.j[3])
                        this.topJournals.push([res.data.j[0], // x
                                               res.data.j[1], // y
                                               1, // size
                                               this.colorDict[res.data.j[2]], // color
                                               res.data.j[3], // name
                                               res.data.j[4],  // id
                                               res.data.j[2], // concept
                                               true, // add
                                            ]) 
                    } else {
                        this.resultText = res.data.ej[6] + " other participant" + (res.data.ej[6]  == 1 ? "" : "s") + " selected " + res.data.ej[1] + " as their " + this.numberText[this.stage] + " choice. "

                        this.topCounts.push(res.data.ej[16+this.stage])
                        this.topJournalNames.push(res.data.ej[1])
                        this.topJournals.push([res.data.ej[7], // x
                                               res.data.ej[8], // y
                                               res.data.ej[6]+1, // size
                                               res.data.ej[4], // color
                                               res.data.ej[1], // name
                                               res.data.ej[0],  // id
                                               res.data.ej[5], // concept
                                               false, // add
                                            ]) 
                    }
                    this.stage++
                    this.searchField = null
                    this.search.journal = null
                    this.search.journals = []
                    if (this.stage==3){
                        this.headerHeight = 150
                    }
            })
        },
        async checkRef (e) {
            e.preventDefault();
            await this.$refs.ref.validate()
            if (this.refValid){
                if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)){
                    // console.log(this.email)
                // const path = process.env.VUE_APP_BASE_URL+'/emailinvite';
                // const payload = {invite: this.invite, email: this.email, first: this.firstName,
                // last: this.lastName, context: "landing", canKeep: this.keepEmail};
                // this.inviteStatus = "processing..."
                // this.showStatus = true
                // this.processing = true
                // await axios.post(path, payload)
                // .then((res) => {
                //     this.inviteStatus = res.data.message
                //     this.emailSent = res.data.valid
                //     if (this.emailSent){
                //     axios.post(process.env.VUE_APP_BASE_URL+'/queryopenalex', {code: res.data.code,
                //                         first: this.firstName, 
                //                         last: this.lastName,
                //                         })
                //     }
                //     this.processing = false
                // })
            } 
            // else {
                // console.log("invalid email")
                // this.inviteStatus = "Invalid Email"
                // this.showStatus = true
                // await this.delay(1000).then(() => {
                //     this.showStatus = false
                //     this.inviteStatus = "nothing yet..."
                // })
            // }
            }
        },
    },
    
}

</script>

<style scoped>
#visualize{
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}
#header1{
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
}
</style>
