<template>
  <v-row no-gutters justify="center">
    <v-col cols="12" class="pa-0">
    <h1 class="text-center pt-4">
        <span class="text-blue-grey-darken-3">Publish or Comparish Consent Form</span>
    </h1>
    </v-col>

    <v-col cols="12" class="pt-4">
    <v-card class="text-left pt-0 mb-2 px-6" elevation="0">
    <p class="pb-0">
            You have been invited to participate in Publish or Comparish. Please read and complete this consent form to participate in the study:<br><br>
            <b>"Assessing the Publication Preferences of Faculty, Researchers, and Students"</b><br>
            This study, conducted by researchers from the University of Colorado Boulder (CU IRB protocol no. 23-0454), has two main parts: the Publish or Comparish game to learn about publication preferences and a questionnaire to contextualize these preferences. In total, participation takes 5 - 10 minutes.
            <br><br>
            <b>Purpose of the Study</b><br>
            The purpose of the study is to investigate the publication preferences of researchers. Participants use pairwise comparisons to rank publication venues and these individual responses help form an aggregate, citation-independent assessment of venues. We also ask general demographic questions to contextualize preferences and see how responses vary by field, career stage, etc. We hope to collect a representative sample of most or all major academic fields and expect about 20,000 participants.
            <br><br>
            <b>Explanation of Procedures</b><br>
            This study consists of two major parts. The first is an online game in which you select places you would publish and then rank them via pairwise comparisons. The second is an online survey in which you answer additional demographic questions about yourself. Each part is estimated to take about 5 minutes.
            <br><br>
            <b>Voluntary Participation and Withdrawal</b><br>
            Whether or not you take part in this research is your choice. You can leave the research at any time and it will not be held against you. Data collected to the point of withdrawal may be used in analysis. You may email <i style="color:blue">facultystudy@colorado.edu</i> to request the deletion of your data.<br><br>
            The person in charge of the research study can remove you from the research study without your approval. Possible reasons for removal include responding at a speed that suggests thoughtless responses.<br><br>
            If you are a CU Boulder student or employee, taking part in this research is not part of your class work or duties. You can refuse to enroll, or withdraw after enrolling at any time, with no effect on your class standing, grades, or job at CU Boulder. You will not be offered or receive any special consideration if you take part in this research.
            <br><br>
            <b>Confidentiality</b><br>
            Information obtained about you for this study will be kept confidential to the extent allowed by law. Research information that identifies you may be shared with the University of Colorado Boulder Institutional Review Board (IRB) and others who are responsible for ensuring compliance with laws and regulations related to research, including people on behalf of the Office for Human Research Protections. The information from this research may be published for scientific purposes; however, your identity will not be given out.<br><br>
            Data associated with the online application is stored in a secure online database and survey data is stored in Qualtrics. The more sensitive demographic data stored in Qualtrics is only individually identifiable by linking that data with the online database. This data will be stored for the duration of analysis.
            <br><br>
            <b>Payment for Participation</b><br>
            You will not be paid to be in this study
            <br><br>
            <b>Questions</b><br>
            If you have questions, concerns, or complaints, or think the research has hurt you, talk to the research team at <i style="color:blue">facultystudy@colorado.edu</i><br><br>
            This research has been reviewed and approved by an IRB. You may talk to them at (303) 735-3702 or <i style="color:blue">irbadmin@colorado.edu</i> if: Your questions, concerns, or complaints are not being answered by the research team. You cannot reach the research team. You want to talk to someone besides the research team. You have questions about your rights as a research subject. You want to get information or provide input about this research.
        </p>
    </v-card>
    </v-col>

    <v-col cols="12" class="pa-0">
        <v-card class="pt-0 pb-0" elevation="0">
        <v-divider color="blue-grey-lighten-2"></v-divider>
        </v-card>
    </v-col>

    <v-col cols="12" class="pa-0">
    <v-card class="text-center pt-4 mb-0 px-6" elevation="0">
    <p v-if="hadRef">Enter your email to receive your unique participation code.</p>
    <p v-else>Enter your referral code and email to receive your unique participation code.</p>
    </v-card>
    </v-col>

    <v-col>
    <v-form v-model="refValid" ref="ref" @submit="checkRef" class="mb-0 pb-2">
    <v-row no-gutters justify="center">
    <v-col cols="12" sm="6" class="pa-0">
      <v-card class="text-center mt-4 ml-6 mr-6" elevation="0">
        <v-text-field
          v-model="invite"
          :rules="rules"
          label="Referral Code"
          variant="underlined"
          required
          hide-details="true"
          color="blue-grey-darken-1"
        ></v-text-field>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6" class="pa-0">
      <v-card class="text-center mt-4 ml-6 mr-6" elevation="0">
        <v-text-field
          v-model="email"
          :rules="rules"
          label="Email"
          variant="underlined"
          required
          hide-details="true"
          color="blue-grey-darken-1"
        ></v-text-field>
      </v-card>
    </v-col>
    <v-col cols="12" class="pa-0 mt-4">
      <v-radio-group v-model="keepEmail" :rules="rules">
        <v-radio value="yes" label="You may keep my email on file and contact me for future research studies."></v-radio>
        <v-radio value="no" label="Please delete my email at the end of this study."></v-radio>
      </v-radio-group>
    </v-col>
    <v-col cols="12" sm="6" class="pa-0">
        <v-card class="text-center mt-0 ml-6 mr-6" elevation="0">
        <v-text-field
            v-model="firstName"
            :rules="rules"
            label="First Name"
            variant="underlined"
            required
            hide-details="true"
            color="blue-grey-darken-1"
        ></v-text-field>
        </v-card>
    </v-col>
    <v-col cols="12" sm="6" class="pa-0">
        <v-card class="text-center mt-0 ml-6 mr-6" elevation="0">
        <v-text-field
            v-model="lastName"
            :rules="rules"
            label="Last Name"
            variant="underlined"
            required
            hide-details="true"
            color="blue-grey-darken-1"
        ></v-text-field>
        </v-card>
    </v-col>
    <v-col cols="12" class="pa-0 mt-4">
        <v-checkbox v-model="checkedConsent" label="I have read and understood the consent form and freely give my consent to participate in this study"></v-checkbox>
    </v-col>
    <v-col cols="12 d-flex justify-center align-end" class="pa-0">
      <v-card class="mt-6 mb-0" elevation="0">
        <v-btn class="mt-0" 
        width="300px"
        color="blue-grey-darken-1"
        variant="tonal"
        :disabled="emailSent || processing || !checkedConsent"
        @click="checkRef">Request Participation Code</v-btn>
      </v-card>
    </v-col>
    
    </v-row>
    </v-form>
    </v-col>

    <v-col cols="12">
    <p class="text-center" :style="{visibility: showStatus ? 'visible' : 'hidden'}">
      {{inviteStatus}}
    </p>
    </v-col>

    <v-col cols="12" class="pa-0">
        <v-card class="pt-0 pb-0 mt-0 mb-0" elevation="0">
        <v-divider color="blue-grey-lighten-2"></v-divider>
        </v-card>
    </v-col>

    <v-col cols="12" sm="8">
    <v-expansion-panels>
    <v-expansion-panel elevation="0">
    <v-expansion-panel-title>Already have a participation code?</v-expansion-panel-title>
    <v-expansion-panel-text>
      <v-form v-model="valid" ref="par" @submit="checkPar" class="mb-0 pb-4">
      <v-row no-gutters justify="center">
      <v-col cols="8" class="pa-0">
        <v-card class="text-center mt-4 ml-0 mr-2 pa-0" elevation="0">
          <v-text-field
            v-model="par"
            :rules="rules"
            label="Participation Code"
            variant="underlined"
            required
            hide-details="true"
            color="blue-grey-darken-1"
          ></v-text-field>
        </v-card>
      </v-col>
      <v-col cols="4 d-flex justify-end align-end" class="pa-0">
        <v-card class="mt-0 ml-2 mr-0 mb-0 pa-0" elevation="0">
          <v-btn class="mt-0" 
          width="150px"
          color="blue-grey-darken-1"
          variant="tonal"
          @click="checkPar">Go!</v-btn>
        </v-card>
      </v-col>
      </v-row>
      </v-form>

    </v-expansion-panel-text>
    </v-expansion-panel>
    </v-expansion-panels>
    </v-col>

    

  </v-row>
</template>

<script>
import axios from 'axios';

export default {
  emits: ["verified"],
  props: ["pgRules"],
  data: () =>({
    rules: [v => !!v || 'Required'],
    valid: false,
    refValid: false,
    par: "",
    firstName: "",
    lastName: "",
    invite: "",
    email: "",
    inviteStatus: "nothing yet...",
    showStatus: false,
    emailSent: false,
    hadRef: false,
    windowWidth: window.innerWidth,
    processing: false,
    checkedConsent: false,
    keepEmail: null,
  }),
  created(){
    if (this.$route.query.r){
      this.invite = this.$route.query.r
      this.hadRef = true
    }
  },
  mounted(){
    window.addEventListener("resize", () => {this.windowWidth = window.innerWidth})
  },
  methods: {
  async checkPar (e) {
    e.preventDefault();
    await this.$refs.par.validate()
    if (this.valid){
        const path = process.env.VUE_APP_BASE_URL+'/getuserdata';
        const payload = {code: this.par, pg_rules: this.pgRules};
        await axios.post(path, payload)
          .then((res) => {
            if (res.data.page >= 0){
              res.data.user_data.page = res.data.page
              this.$emit('verified', res.data.user_data)
            } else {
              this.par = ""; this.$refs.par.validate();
            }
        })
      }
    }, 
    async checkRef (e) {
    e.preventDefault();
    await this.$refs.ref.validate()
    if (this.refValid){
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)){
        const path = process.env.VUE_APP_BASE_URL+'/emailinvite';
        const payload = {invite: this.invite, email: this.email, first: this.firstName,
        last: this.lastName, context: "landing", canKeep: this.keepEmail};
        this.inviteStatus = "processing..."
        this.showStatus = true
        this.processing = true
        await axios.post(path, payload)
          .then((res) => {
            this.inviteStatus = res.data.message
            this.emailSent = res.data.valid
            if (this.emailSent){
            axios.post(process.env.VUE_APP_BASE_URL+'/queryopenalex', {code: res.data.code,
                                first: this.firstName, 
                                last: this.lastName,
                                })
            }
            this.processing = false
        })
      } else {
        this.inviteStatus = "Invalid Email"
        this.showStatus = true
        await this.delay(1000).then(() => {
            this.showStatus = false
            this.inviteStatus = "nothing yet..."
        })
      }
      }
    },
    delay(ms){ return new Promise(res => setTimeout(res, ms))},
  }
}
</script>