<template>
<div>
<!-- splash page -->
<v-row class="" justify="center" no-gutters v-if="nClicked < 4 && splash === true">
    <v-col cols="12" class="pt-4 ma-0" align="left">
    <v-card elevation="0" class="px-2">

    Using the information you've provided and data from the scholarly catalog OpenAlex, 
    we will now ask you about specific venues. We are interested in whether or not you would publish at each venue. Ask yourself: 
    <br><br>
    <p class="text-center px-12"><i>Would a publication at the venue mean something to you?<br>
    Would you personally like to, hope to, or want to publish there?</i></p>
    <br>
    After responding "YES" or "NO" to 20 specific venues you will have the opportunity to use a search box to directly indicate other places you would publish.
    Upon completing this stage you will compare the venues you have selected.
    <br><br>
    <div class="text-center">
    <v-btn class="mt-2"
            width="150px"
            color="blue"
            variant="tonal"
            @click="splash = false; this.scroll()">continue</v-btn>
    </div>
    </v-card>
    </v-col>
</v-row>

<!-- main page -->
<v-row class="" justify="center" no-gutters v-else>
    <v-col v-if="!dialog" cols="12" class="pa-0 ma-0" align="center">
    
    <v-card elevation="0" class="ma-0">
    <p class="text-center pt-4 mb-0" style="font-size:18px">
    Would you personally like to, hope to, or want to publish in...
    </p>
    </v-card>
    </v-col>
    
    <v-col v-if="!dialog" cols="12" sm="10" class="pa-0 ma-0" >
        <v-card class="px-2 d-flex justify-center align-center text-center" min-height="72px" elevation="0">
        <p ref="search" style="font-size:24px">
            {{journal.name}}
        </p>    

        </v-card>
    </v-col>

    <v-col v-if="!dialog" cols="6" class="px-0 pt-0 pb-0" >
        <v-card :class="noClass" elevation="0" >
        <v-btn :class="{'disable-events': submitting}"
        min-width="87px" id="no" @click="submitDiscoverJournal(journal, false)"
        color="blue-grey-darken-1"
        elevation="2"
        variant="outlined"
        :disabled="!journal.valid">

        <v-icon class="pr-4" color="blue-grey-darken-1" size="small">
            mdi-arrow-left-box
        </v-icon>
        <div style="font-size: 1.2em">{{noText}}</div>
        
        </v-btn>
        </v-card>
    </v-col>
    <v-col v-if="!dialog" cols="6" class="px-0 pt-0 pb-0" >
        <v-card :class="yesClass" elevation="0">
        <v-btn :class="{'disable-events': submitting}" min-width="87px" id="yes" @click="submitDiscoverJournal(journal, true)" 
        color="blue-grey-darken-1"
        elevation="2"
        variant="outlined"
        :disabled="!journal.valid">
        <div style="font-size: 1.2em">{{yesText}}</div>
        <v-icon class="pl-4" color="blue-grey-darken-1" size="small">
            mdi-arrow-right-box
        </v-icon>
        </v-btn>
        </v-card>
    </v-col>

    <v-col v-if="!dialog" cols="12" class="pt-1 text-center" style="font-size: 1em">
        <v-btn
        size="small"
        variant="outlined"
        color="blue-grey-lighten-2"
        :disabled="lastSeen === null"
        @click="undoLast()"
        >
        undo
        </v-btn>
    </v-col>
 
    <v-col v-if="!dialog" cols="12" class="px-2 pt-2 text-center">
        <p style="font-size:12px"><i>keyboard shortcuts: left arrow key for NO, right arrow key for YES</i></p>
    </v-col>

    <v-col v-if="nClicked >= 23" cols="12" sm="8" class="pa-0 ma-0" >
        <v-card class="pa-0 justify-center align-end" :min-height="search.height" elevation="0">
        <v-card-text v-if="dialog" class="mb-0 pb-0">Good work! You can return to this stage and discover more venues later, 
            but if there are any venues you wish to add now you may search for them here. When you are ready, click continue to compare the places you would publish.
        </v-card-text>
        <v-autocomplete justify="center" align="center" 
            class="search pt-0 ma-0 px-2 custom-placeholder-color"
            id="search"
            ref="search"
            v-model="search.journal"
            :items="search.journals"
            item-title="name"
            item-value="id"
            v-model:search="searchField"
            return-object
        
            :clearable="false"
            :disabled="showStatus"
            
            :placeholder="placeholderText"
            :single-line="true"
            :hide-details="true"
            :hide-no-data="true"
            density="comfortable"
            variant="underlined"
            prepend-inner-icon="mdi-magnify"
            color="blue-grey-darken-1"

            @focus="canCut = false"
            @blur="canCut = true"
            @update:modelValue="searchChange()"
            :menu-props="menuProps"
        >
        </v-autocomplete> 

        </v-card>
    </v-col>
    <v-col v-if="nClicked >= 23" cols="12" class="mt-2">
        <v-card min-height="32px" elevation="0">
        <p class="text-center" :style="{visibility: showStatus ? 'visible' : 'hidden'}">
        added <i>{{addMessage}}</i>
        </p>
        </v-card>
    </v-col> 

    <v-col v-if="dialog" cols="12" class="pa-0 d-flex justify-center">
        <v-card class="mt-0 mb-2" elevation="0">
        <v-btn class="mt-0" 
        width="150px"
        color="blue"
        variant="tonal"
        type="submit"
        @click="pageChange(2)">continue</v-btn>
        </v-card>
    </v-col>

    <v-col v-show="!completed" cols="12" sm="8">
        <v-card class="mx-4 pb-4 mt-4 text-left" variant="text">
            <h3 class="d-flex align-center justify-center text-center mb-1"> places you would publish:</h3>
            <li v-for="j in knowns.slice().reverse()" :key="j[1]" 
            :style="{'font-weight': j[1] === this.hovering ? 'bold' : 'normal'}" >
                {{j[1]}}
            </li>
        </v-card>
    </v-col>

    <v-col cols="12 d-flex justify-center">
        <v-card v-show="!dialog" id="visualize" class="mx-2 mt-n10 mb-n4 text-center" :width="simulation.simWidth" 
                :height="simulation.simHeight" variant="plain">
            <DiscoverJournalsSim
            @node-click="nodeClick"
            :simWidth="simulation.simWidth"
            :simHeight="simulation.simHeight"
            :knowns="knowns"
            :unknowns="unknowns"
            :nClicked="nClicked"
            :nActions="nActions"
            :preferences="preferences"
            v-model:selected="journal"
            v-model:hovering="hovering"
            />
        </v-card>
    </v-col>
</v-row>
</div>
</template>

<script>
import axios from 'axios';
import DiscoverJournalsSim from '../DiscoverJournalsSim.vue'

export default {
    components: {
        DiscoverJournalsSim
    },
  props: ["code", "completed", "pgRules", "knowns", "unknowns", "candidate", 
          "candidates", "pair", "minCompared", "preferences"],
  data: () =>({
    Ncandidates: 3,
    journal: {'name':'', 'valid': false},
    searchField: null,
    menuProps: {maxWidth: "100px"},
    search: {
        promptCount: localStorage.nDiscovered,
        journal: null,
        journals: [],
        searchColor: "blue",
        isLoading: false,
        height: localStorage.nDiscovered >= 3 ? "60px" : "40px"
    },
    simulation: {
        simWidth: 686,
        simHeight: 398,
    },
    yesClass: "mt-2 mb-0 ml-2 mr-12 d-flex justify-start",
    noClass: "mt-2 mb-0 ml-12 mr-2 d-flex justify-end",
    secondPrompt: "Would you publish in...",
    noText: "No",
    yesText: "Yes",
    minDiscovered: 20,
    lockIcon: "mdi-lock" ,
    compareColor: "blue-darken-3",
    nClicked: 0,
    nActions: 0,
    showStatus: false,
    addMessage:"",
    dialog: false,
    clickedArrow: false,
    submitting: false,
    splash: true,
    placeholderText: "search for a place you'd publish (click or press /)",
    hovering: "",
    canCut: true,
    lastSeen: null,
  }),
    created() {
    this.scroll()
    window.addEventListener("keydown", this.discoverListener);
    this.updateState()
    },
    mounted() {
        this.simulation.simWidth = Math.min(window.innerWidth,686)
        if (window.innerWidth < 650){this.placeholderText = "search for a place you'd publish"}
                                                 else {this.placeholderText =  "search for a place you'd publish (click or press /)"}
        window.addEventListener("resize", () => {this.simulation.simWidth = Math.min(window.innerWidth,686); 
                                                 if (window.innerWidth < 650){this.placeholderText = "search for a place you'd publish"}
                                                 else {this.placeholderText =  "search for a place you'd publish (click or press /)"}});

    },
    unmounted() {
    window.removeEventListener("keydown", this.discoverListener);
    },
  watch: {
        searchField(a){
            this.search.isLoading = true
            const path = process.env.VUE_APP_BASE_URL+'/searchjournals';
            if (a !== null && a !== "" && !this.search.journal){
                axios.post(path, {string: a})
                .then((res) => {
                    const new_journals = [];
                    for (let i = 0; i < res.data.length; i++){
                    if (!this.knowns.map(x => x[1]).includes(res.data[i][1])){
                            new_journals.push({"id":res.data[i][0],"disabled": false,
                                    "name":res.data[i][1], "valid":true})
                        } else {
                            new_journals.push({"id":res.data[i][0],"disabled": true,
                                    "name":res.data[i][1] + " (added)", "valid":true})
                        }
                }
                this.search.journals = new_journals;
                })
            } else {
                this.search.journals = [];
            }
            this.search.isLoading = false;
        },
    },
  methods: {
    undoLast(){
        this.journal = this.lastSeen
        this.lastSeen = null
        this.$emit('update-progress',1,-1)
    },
    async submitDiscoverJournal(journal, known, nextJournal = null) {
        this.submitting = true
        const path = process.env.VUE_APP_BASE_URL+'/addjournal';
        const payload = {code: this.code, 
                        id: journal.id, 
                        known: known};
        await axios.post(path, payload)
            .then((res) => {
                this.lastSeen = {'id':journal.id, 'name':journal.name, 'valid':true}
                this.$emit('update:knowns', res.data.knowns)
                this.$emit('update:unknowns', res.data.unknowns)
                this.$emit('update:candidates', res.data.candidates)
                this.$emit('update:pair', res.data.pair)
                this.$emit('update:minCompared', res.data.minCompared)
                this.$emit('update-progress',1,1)
                this.$emit('update:candidate', res.data.newCandidate)
                this.$nextTick(() => {
                    this.updateState(nextJournal)
                    this.nActions++
                    if (this.nClicked >= 4 && !this.dialog){
                        document.getElementById("no").blur()
                        document.getElementById("yes").blur()
                    }
                    this.submitting = false
                })
        })
    },
    updateState(nextJournal=null){
        if (nextJournal){
            this.journal.id = nextJournal.id
        }
        else if (this.candidate){
            this.journal = {'id':this.candidate[0], 'name':this.candidate[1]+"?", 'valid':true}
        } else {
            this.journal = {'name':"", 'valid':false}
        }
        this.nClicked = Math.max(this.nClicked, this.knowns.length + this.unknowns.length)
        this.showStatus = false
        if (this.nClicked == 3){
            this.search.height = "60px"
            this.secondPrompt = "Would you publish in..."
            this.noText = "No"
            this.yesText = "Yes"
        }
        if (this.nClicked >= 4){
            this.splash = false
            this.secondPrompt = "Would you publish in..."
            this.noText = "No"
            this.yesText = "Yes"
        }
        if (!this.completed & this.nClicked >= 23){
            this.canCompare = true
            this.lockIcon = "mdi-lock-open"
            this.$emit('update:canCompare', this.canCompare)
            this.dialog = true
        }
    },

    nodeClick(d){
        this.journal = d
    },
    delay(ms){ return new Promise(res => setTimeout(res, ms))},
    async searchChange(){
        if (this.search.journal.disabled){
            this.addMessage = this.search.journal.name.slice(0,-8)
            this.$nextTick(() => {
                this.search.journal = null
                this.searchField = ""
                this.search.journals = []
            }) 
            this.showStatus = true
            document.getElementById("search").blur()
            setTimeout(() => {this.showStatus = false}, 2000)
            return
        }
        if (this.search.journal !== null){
            this.addMessage = this.search.journal.name
            let sameJournal = false
            if (this.search.journal.name+"?" === this.journal.name){
                sameJournal = true
            }
            let oldJournal = this.journal
            this.journal = {'name':oldJournal.name, 'id':this.search.journal.id, 'valid':true}

            this.$nextTick(() => {
                this.search.journal = null
                this.searchField = ""
                this.search.journals = []
            }) 
            this.showStatus = true
            document.getElementById("search").blur()
            if (this.nClicked < 3){
                this.journal.name  = ""
                await this.delay(1000).then(() => {
                    this.submitDiscoverJournal(this.journal, true)
                })
            } else {
                setTimeout(() => {this.showStatus = false}, 2000)
                if (sameJournal){
                    oldJournal = null
                }
                await this.delay(1000).then(() => {
                    this.submitDiscoverJournal(this.journal, true, oldJournal)
                })   
            }
        }
    },
    pageChange (i) {
        this.$emit('page-change', i)
    },
    onResize (){
        this.menuProps = {'maxWidth': this.$refs.search.$el.clientWidth+"px"}
    },
    discoverListener(event) {
        if (!this.dialog && this.canCut){
            if (event.key === "ArrowLeft" && !this.submitting && this.nClicked >= 3 && !this.splash) {
                this.ripple(document.getElementById("no"))
                document.getElementById("no").click()
                this.clickedArrow = true
            } else if (event.key === "ArrowRight" && !this.submitting && this.nClicked >= 3 && !this.splash) {
                this.ripple(document.getElementById("yes"))
                document.getElementById("yes").click()
                this.clickedArrow = true
            }
        }
        if (this.dialog || this.completed){
            if (event.key === "/" && !this.splash && this.canCut) {
                setTimeout(function() {
                    document.getElementById("search").click()
                }, 10)
            }
        }
    },
    ripple($el) {
        let ev = new Event("mousedown")
        ev.preventDefault()
        let offset = $el.getBoundingClientRect()
        ev.clientX = offset.left + offset.width/2
        ev.clientY = offset.top + offset.height/2
        $el.dispatchEvent(ev)

        setTimeout(function() {
            $el.dispatchEvent(new Event("mouseup"))
        }, 10)
    },
    scroll() {
        window.scrollTo(0,0);
    },
  }
}
</script>

<style>
.disable-events {
  pointer-events: none;
}

.custom-placeholder-color input::placeholder {
  color: black !important;
  opacity: .7;
}
</style>