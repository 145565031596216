<template>
<div>
  <v-container>
    
    <v-row justify="center" v-show="loaded">
        <v-sheet
          class="parentSheet"
          :height="sheetHeight">
          <div v-show="userData.page !== -1 && this.consented">
          <v-toolbar
              color="white"
              density="compact"
              :height="60"
              v-if="!userData.playWrapped || userData.page !== 3"
          >
              <v-row class="no-gutters">
              <v-col cols="12">
              <div class="custom-row">

              <v-btn class="btn1 pl-0 pr-0" style="font-size:12px;"
                :ripple="false"
                :style="userData.completed ? 'pointer-events:none;' : ''"
                variant="plain"
                :disabled="true"
                :prepend-icon="true ? 'mdi-star' : 'mdi-lock'"
                :color="consented || userData.page > 0 ? 'blue' :  'red-lighten-2'"
                @click="updatePage(0)" stacked>Start</v-btn>
              <div class="bar1">
                <v-progress-linear class="custom-progress1" v-show="!userData.completed"
                :color="userData.completed || userData.page == 0 ? 'blue-lighten-3' : 'blue-lighten-5'" 
                bg-color="grey"
                :model-value="progressBars[0]*100"></v-progress-linear>
              </div>

              <v-btn class="btn2 pa-0" style="font-size:12px;"
                :ripple="false"
                variant="plain"
                :disabled="(userData.completed && userData.page != 1) ? false : true"
                :prepend-icon="userData.completed || userData.page >= 1 ? 'mdi-magnify' : canDiscover ? 'mdi-lock-open' : 'mdi-lock'"
                :color="canDiscover ? 'blue' :  'blue-grey'"
                @click="updatePage(1)" stacked>Discover</v-btn>

              <div class="bar2">
                <v-progress-linear class="custom-progress2" v-show="!userData.completed"
                :color="userData.completed || userData.page == 1 ? 'blue-lighten-3' : 'blue-lighten-5'" 
                bg-color="grey"
                :model-value="Math.min(100,(progressBars[1]-3)/(pgRules[1][1]-3)*100)"></v-progress-linear>
              
              </div>

              <v-btn class="btn3 pa-0" style="font-size:12px"
                :ripple="false"
                variant="plain"
                :disabled="(userData.completed && userData.page != 2) ? false : true"
                :prepend-icon="userData.completed || userData.page >= 2 ? 'mdi-scale-balance' : canCompare ? 'mdi-lock-open' : 'mdi-lock'"
                :color="progressBars[1] >= pgRules[1][1] ? 'blue' :  'blue-grey-darken-3'"
                @click="userData.completed ? updatePage(2) : dialog = true;" stacked>Compare</v-btn>

              <div class="bar3">
                <v-progress-linear class="custom-progress3" v-show="!userData.completed"
                color="blue-lighten-3"
                bg-color="grey"
                :model-value="progressBars[2]/(Math.min(2*nKnowns,nKnowns*(nKnowns-1)/2))*100" ></v-progress-linear>
              </div>

              <v-btn class="btn4 pl-0 pr-2" style="font-size:12px;"
                :ripple="false"
                variant="plain"
                :disabled="userData.completed ? false : !canShare"
                :prepend-icon="userData.completed ? 'mdi-rocket' : canShare ? 'mdi-lock-open' : 'mdi-lock'"
                :color="userData.completed ? 'blue' : progressBars[2] >= Math.min(2*nKnowns,nKnowns*(nKnowns-1)/2) ? 'blue' :  'blue-grey'"
                @click="userData.completed ? updatePage(3) : dialog = true;" stacked>{{userData.completed ? "Explore" : "?"}}</v-btn> 
               </div>
              </v-col>
              </v-row>
              
          </v-toolbar>
          <v-divider v-if="!userData.playWrapped"></v-divider>
          </div>

          <LandingPage
          v-if="userData.page == -1"
          :pgRules="pgRules"
          @verified="updateCode"
          />

          <StartJournals
          v-if="userData.page == 0"
          :code="userData.code"
          v-model:candidate="userData.newCandidate"
          v-model:knowns="userData.knowns"
          v-model:unknowns="userData.unknowns"
          v-model:candidates="userData.candidates"
          v-model:pair="userData.pair"
          v-model:consented="consented"
          @submit-info="updatePage"
          @update-progress="updateProgress"
          />

          <DiscoverJournals
          v-if="userData.page == 1"
          :code="userData.code"
          :completed="userData.completed"
          :pgRules="pgRules"
          :preferences="userData.preferences"
          v-model:candidate="userData.newCandidate"
          v-model:canCompare="canCompare"
          v-model:knowns="userData.knowns"
          v-model:unknowns="userData.unknowns"
          v-model:candidates="userData.candidates"
          v-model:pair="userData.pair"
          v-model:minCompared="userData.minCompared"
          @page-change="updatePage"
          @update-progress="updateProgress"
          />

          <CompareJournals
          v-if="userData.page == 2"
          :code="userData.code"
          :invite="userData.ref"
          :completed="userData.completed"
          :pgRules="pgRules"
          :knowns="userData.knowns"
          :unknowns="userData.unknowns"
          :dialog2="dialog"
          v-model:canShare="canShare"
          v-model:preferences="userData.preferences"
          v-model:minCompared="userData.minCompared"
          v-model:pair="userData.pair"
          @complete-survey="completeSurvey"
          @page-change="updatePage"
          @update-progress="updateProgress"
          />

          <ShareJournals
          v-if="userData.page == 3"
          :code="userData.code"
          :invite="userData.ref"
          :viz="userData.viz"
          :completed="userData.completed"
          :pgRules="pgRules"
          :knowns="userData.knowns"
          :unknowns="userData.unknowns"
          :candidates="userData.candidates"
          :preferences="userData.preferences"
          :largest="userData.largest"
          :wrapping="userData.wrapping"
          :rewrapped="userData.rewrapped"
          :ranking="userData.ranking"
          :overallRanking="userData.overallRanking"
          :bounds="userData.bounds"
          :qualtrics_link="userData.qualtrics_link"
          v-model:justFinished="justFinished"
          v-model:playWrapped="userData.playWrapped"
          v-model:navPage="userData.navPage"
          v-model:invited="userData.invited"
          v-model:shared="userData.shared"
          @page-change="updatePage"
          />
        </v-sheet>
    </v-row>
  </v-container>
</div>
</template>


<script>
import axios from 'axios';
import LandingPage from './LandingPage.vue'

import StartJournals from './StartJournals.vue'
import DiscoverJournals from './DiscoverJournals.vue'
import CompareJournals from './CompareJournals.vue'
import ShareJournals from './ShareJournals.vue'

export default {
  name: 'RankJournals',
  components: {
    LandingPage,
    StartJournals,
    DiscoverJournals,
    CompareJournals,
    ShareJournals,
  },
  data: () => ({
    sheetHeight: "800px",
    sheetWidth: "800px",
    userData: {page: -2, hash: "", code: "", ranking: null, overallRanking: null},
    pgRules: [null, [10,23], 3, 3, null],
    canDiscover: false,
    canCompare: false,
    canShare: false,
    progressBars: [0,0,0],
    circles: [{'v':0,'c':'blue-grey-lighten-1'},
              {'v':0,'c':'blue-grey-darken-1'},
              {'v':0,'c':'blue-grey-darken-3'}],
    loaded: false,
    nKnowns: 3,
    consented: false,
    dialog: false,
    justFinished: false,
    copyIcon: "mdi-content-copy",

  }),
  created (){
    if (this.$route.query.p){
      this.hash = this.$route.query.p
      const path = process.env.VUE_APP_BASE_URL+'/getuserdata';
      const payload = {code: this.hash, pg_rules: this.pgRules}
      axios.post(path, payload)
        .then((res) => {
          if (res.data.user_data){
            this.userData = res.data.user_data
            this.consented = this.userData.consented
            this.userData.playWrapped = false
            this.updatePage(res.data.page)
          } else {
            this.loaded = true
            this.userData.page = -1
          }
        })
    } else {
      this.loaded = true
      this.userData.page = -1
    }
  },
  mounted(){
    const progressBar = this.$el.querySelector(".custom-progress1");
    const progressBar2 = this.$el.querySelector(".custom-progress2");
    const progressBar3 = this.$el.querySelector(".custom-progress3");
    const btn1 = this.$el.querySelector(".btn1");
    const btn2 = this.$el.querySelector(".btn2");
    const btn3 = this.$el.querySelector(".btn3");
    const btn4 = this.$el.querySelector(".btn4");

    const setProgressBarWidth = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 800){
      const pixels = btn2.getBoundingClientRect().left - btn1.getBoundingClientRect().left - 35

      progressBar.style.width = `${pixels}px`;
      } else {
        progressBar.style.width = `${132}%`;
      }
    };
    setProgressBarWidth();
    window.addEventListener("resize", setProgressBarWidth);


    const setProgressBarWidth2 = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 800){
      const pixels = btn3.getBoundingClientRect().left - btn2.getBoundingClientRect().left - 40

      progressBar2.style.width = `${pixels}px`;
      } else {
        progressBar2.style.width = `${115}%`;
      }
    };
    setProgressBarWidth2();
    window.addEventListener("resize", setProgressBarWidth2);

    const setProgressBarWidth3 = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 800){
      const pixels = btn4.getBoundingClientRect().left - btn3.getBoundingClientRect().left - 50

      progressBar3.style.width = `${pixels}px`;
      } else {
        progressBar3.style.width = `${115}%`;
      }
    };
    setProgressBarWidth3();
    window.addEventListener("resize", setProgressBarWidth3);
  },
  methods: {
    updateCode (d) { 
      this.userData = d
      this.consented = this.userData.consented
      this.userData.playWrapped = false
      this.userData.navPage = true
      this.updatePage(this.userData.page)
      this.$router.push({ path: '', query: { p: this.userData.hash }})
      this.hash = this.userData.hash
      this.scroll()
    },
    scroll() {
        window.scrollTo(0,0);
    },
    updatePage(p){
      if (p == 3){
        const path = process.env.VUE_APP_BASE_URL+'/rewrapuser';
        const payload = {code: this.userData.code}
        axios.post(path, payload)
          .then((res) => {
            this.userData.rewrapped = res.data['rewrapped']
            this.userData.ranking = res.data['ranking']
            this.userData.overallRanking = res.data['overallRanking']
            this.userData.bounds = res.data['bounds']
            this.userData.page = p
            this.userData.navPage = this.userData.playWrapped ? false : true
            this.reloadProgress()
          })
      }
      else {
        this.userData.page = p
        this.reloadProgress()
      }
    },
    async reloadProgress(){
      if (this.userData.page > 0){
        this.progressBars[0] = 1
        this.canDiscover = true
      }

      let k = this.userData.knowns
      let u = this.userData.unknowns
      let r = k.length + u.length
      let p = this.userData.preferences.length
      this.nKnowns = Math.max(this.nKnowns,k.length)
      this.progressBars[1] = r
      this.progressBars[2] = p

      if (r >= this.pgRules[1][1]){
        this.canCompare = true
      }

      this.canShare = p >= (Math.min(2*this.nKnowns,this.nKnowns*(this.nKnowns-1)/2))
      this.dialog = false
      this.loaded = true
    },
    async updateProgress(bar, increment){
      this.progressBars[bar] += increment
      if (this.progressBars[0] >= 1){
        this.canDiscover = true
      }
      this.nKnowns = this.userData.knowns.length
      this.canShare = this.userData.preferences.length >=(Math.min(2*this.nKnowns,this.nKnowns*(this.nKnowns-1)/2))
    },
    async completeSurvey(){
      const path = process.env.VUE_APP_BASE_URL+'/completesurvey';
      const payload = {code: this.userData.code}
      axios.post(path, payload)
        .then((res) => {
          this.userData.completed = res.data.completed
          this.userData.viz = res.data.viz
          this.userData.wrapping = res.data.wrapped
          this.userData.ranking = res.data.ranking
          this.userData.overallRanking = res.data.overallRanking
          this.userData.playWrapped = true
          this.justFinished = true
          this.updatePage(3)
        })
    },
    delay(ms){ return new Promise(res => setTimeout(res, ms))},
    async copyLink(){
        let url = window.location.origin + '?r='+this.userData.ref
        navigator.clipboard.writeText(url)
        this.copyIcon = "mdi-check"
        await this.delay(2000).then(() => {
            this.copyIcon = "mdi-content-copy"
        })

    },
  },
}
</script> 

<style scoped>
.custom-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bar1{
  position: relative;
  flex: 1;
  overflow: visible;
}

.custom-progress1 {
  width: 140%; 
  position: absolute;
  left: -18% !important; 
  transform: translateX(0%) !important;
  top:-12px !important;
}
.custom-progress2 {
  width: 100%; 
  position: absolute;
  left: -8% !important; 
  transform: translateX(0%) !important;
  top:-12px !important;
}
.custom-progress3 {
  width: 100%; 
  position: absolute;
  left: -8% !important; 
  transform: translateX(0%) !important;
  top:-12px !important;
}

.bar2 {
  position: relative;
  overflow: visible;
  flex: 2;
}

.bar3 {
  position: relative;
  overflow: visible;
  flex: 2;
}

.parentSheet {
    min-width: 800px;
    max-width: 800px;
  }

@media only screen and (max-width: 800px) {
  .parentSheet {
    min-width: 100vw;
    max-width: 100vw;
  }
}

@media only screen and (max-width: 800px) {
  .custom-progress1 {
  min-width: 52px; 
  left: -20px !important; 
}
  .custom-progress2 {
  min-width: 70px; 
  left: -20px !important; 
}
  .custom-progress3 {
  min-width: 60px;
  left: -15px !important; 
}
}

</style>