<template>
<v-row v-if="navPage" no-gutters justify="center">
    <v-col cols="12" class="text-center mx-2 mt-2 pt-0">
    <p><i>Use the buttons above to navigate the stages or...</i></p>
    </v-col>
    <v-col cols="12" sm="9" class="pt-0">
        <v-card elevation="5" height="150px" class="mt-2 mx-2 px-2 text-center align-center justify-center d-flex">
            <div>
                <h3>Tell us more about yourself!</h3>
                Answer some basic demographic questions and reflect on your publication preferences to
                help us do more science.<br>
            <v-btn
                    color="blue-lighten-5"
                    class="mt-2"
                    :href="!qualtrics_link ? backup_link : qualtrics_link"
                    target='_blank'
                    height="28px"
                >
                Take Secure Qualtrics Survey
                </v-btn>
            </div>
        </v-card>
    </v-col>

    <v-col cols="12" sm="9" class="pt-0">
        <v-card elevation="5" height="150px" class="mt-2 mx-2 px-2 text-center align-center justify-center d-flex">
            <div>
                <h3>Invite colleagues, students, or friends!</h3>
                Copy and share your custom invite link with anyone you think might enjoy participating.
                Feel free to post your link online too.<br>
            <v-btn
                    color="blue-lighten-5"
                    class="mt-2"
                    @click="copyLink"
                    height="28px"
                >
                <v-icon :icon="copyIcon" size="20px" class="pr-2"></v-icon>
                copy custom invite link

                </v-btn>
            </div>
        </v-card>
    </v-col>

    <v-col cols="12" sm="9" class="pt-0">
        <v-card elevation="5" height="150px" class="mt-2 mx-2 px-2 text-center align-center justify-center d-flex">
            <div>
                <h3>Interact with your data!</h3>
                See your selected venues embedded alongside the places others would publish. Find out how your ranking
                compares to that of others.<br>
                <v-btn
                    color="blue-lighten-5"
                    class="mt-2"
                    @click="this.$emit('update:navPage', false); this.scroll()"
                    height="28px"
                >
                <v-icon icon="mdi-palette-outline" size="20px" class="pr-2"></v-icon>
                visualize

                </v-btn>
            </div>
        </v-card>
    </v-col>

    <v-col cols="12" sm="9" class="pt-0">
        <v-card elevation="5" height="150px" class="mt-2 mx-2 px-2 text-center align-center justify-center d-flex">
            <div>
                <h3>Discover and Compare more journals!</h3>
                Look for more places you would publish or make more comparisons between the venues you have already selected.<br>
                <v-btn
                    color="blue-lighten-5"
                    class="mr-4 mt-2"
                    @click="pageChange(1)"
                    width="20px"
                    height="28px"
                    
                >
                <v-icon icon="mdi-magnify" size="large"></v-icon>
                </v-btn>
                
                <v-btn
                    color="blue-lighten-5"
                    class="ml-4 mt-1"
                    @click="pageChange(2)"
                    width="20px"
                    height="28px"
                >
                <v-icon icon="mdi-scale-balance" size="large"></v-icon>
                </v-btn>
            </div>
        </v-card>
    </v-col>

</v-row>

<v-row v-if="!navPage" no-gutters justify="center">
    <v-col cols="12" class="mt-0 mb-n6" v-show="isPlaying == false">
        <v-row no-gutters>        
            <v-col cols="12">
            <v-radio-group inline class="pr-2 pb-2 small-radio" density="compact" v-model="visualizationTab">
                <v-spacer></v-spacer>
                <v-radio label="Summary" value="1" class="mb-0"></v-radio>
                <v-radio label="Map" value="2" class="mb-0"></v-radio>
                <v-radio label="Ranking" value="3"></v-radio>
            </v-radio-group>
            </v-col>

            <div style="position: relative; z-index: 1" v-if="visualizationTab=='3'" class="ml-n16 mt-4">
            <v-switch class="rankSwitch" density="compact" v-model=rankView true-value=1 false-value=0></v-switch>
            <p class="rankLabel ml-n12">{{rankLabels[rankView]}}</p>
            </div>
            
            <div style="position: relative; z-index: 1" v-if="visualizationTab=='2'" class="mt-16">
            <div class="mapTools mt-16 ml-13">
                <div v-for="b in groupCandidates" :key=b.value class="d-flex align-center" style="text-align:center;">
                    <v-btn 
                        :ripple="false"
                        outlined
                        border
                        :color="b.state ? 'black' : 'white'"
                        size="14px"
                        @click="b.state=!b.state; this.groups = this.groupCandidates.filter(x => x['state']).map(x => x['value'])"
                    >
                    <v-icon icon="mdi-check" size="12px" color="white"></v-icon>
                    </v-btn>
                    <v-label class="mapTools2">{{b.label}}</v-label>
                </div>
            </div>  
                     
            </div>
        </v-row>
    </v-col>

    <v-col cols="12" class="mb-0" v-if="visualizationTab == '3'" id="ranking">
        <v-row no-gutters>
                
                <v-col cols="3" ref="rank" class="ml-0 mt-4">
                    
                    <v-card id="visualize" class="" :width="Math.min(700,Math.max(375,rankWidth - 110))"
                            :height="rankHeight" variant="text">
                        <UserRanking
                        :fullWidth="rankWidth"
                        :rankHeight="rankHeight"
                        :knowns="knowns"
                        :preferences="preferences"
                        :ranking="ranking"
                        :bounds="bounds"
                        :selected="selected"
                        :vizData="vizData"
                        :overallRanking="overallRanking"
                        :rankView="rankView"
                        @clicked="clicked"
                        />
                    </v-card>
                </v-col>
                <v-col cols="9">
                </v-col>

        </v-row>
    </v-col>
    
    <div v-if="visualizationTab == '1'">
    <v-col cols="12" class="mt-0 pa-0">
        <WrappedExperience
        :code="code"
        :wrapping="wrapping"
        :ranking="ranking"
        :overallRanking="overallRanking"
        :rewrapped="rewrapped"
        :playWrapped="playWrapped"
        :simWidth="simWidth"
        @changeState="changeWrappedState"
        @endWrap="endWrap"
        />
    </v-col>
    </div>

    <div v-show="visualizationTab == '2' && this.colorDict">
        <div v-if="clickedTab2">
        <v-col cols="12" class="ma-0 pa-0" ref="sim" id="map">
            <v-card id="visualize" class="mx-0 text-center" 
                    :height="simHeight" variant="text">
                <ShareJournalsSim
                :simWidth="simWidth"
                :simHeight="simHeight"
                :vizData="vizData"
                :selected="selected"
                :ranking="ranking"
                :overallRanking="overallRanking"
                :colorDict="colorDict"
                :groups="groups"
                @clicked="clicked"
                />
            </v-card>
        </v-col>
        </div>
    </div>

</v-row>
</template>


<script>
import axios from 'axios';
import ShareJournalsSim from '../ShareJournalsSim.vue'
import UserRanking from '../UserRanking.vue'
import WrappedExperience from './WrappedExperience.vue'

export default {
        components: {
        UserRanking,
        WrappedExperience,
        ShareJournalsSim
    },
    props: ["code", "invite", "viz", "completed", "pgRules",
            "knowns", "unknowns", "candidates", "wrapping", "rewrapped","bounds","justFinished",
            "preferences", "largest", "ranking", "overallRanking", "invited", "shared", "playWrapped","navPage",
            "qualtrics_link"],
    emits: ["pageChange", "update:playWrapped","update:navPage","update:justFinished"],
  data: () => ({
    header: "Thanks for participating!",
    selected: {"name": "", "valid": false},
    visualizationTab: "1",
    email: "",
    rules: [
            v => !!v || 'Required',
            v => v.length <= 50 || 'No more than 50 characters',
        ],
    valid: false,

    validKey: false,
    otherExists: false,
    key: "",
    other: [[],[],[],[], "(_____)","#ABABAB"],
    inviteStatus: "nothing yet...",
    showStatus: false,

    simWidth: 800,
    simHeight: 800 - 160,
    rankWidth: 666,
    rankHeight: 680,
    vizData: {},

    menuProps: {maxWidth: "100px"},
    copyIcon: "mdi-content-copy",
    isPlaying: false,
    rankView: 0,
    rankLabels: ['Based on Your Comparisons', 'Based on All Comparisons'],
    groups: ['ku','uk'], 
    groupCandidates: [{'class': "mt-n8",'class2': "pt-0", 'state':true, 'value':"ku" , 'label':"You'd Publish" },
                      {'class': "mt-4 ml-n4",'class2': "pt-4", 'state':true, 'value':"uk" , 'label':"Others Would" },
                      {'class': "mt-16 ml-n4",'class2': "pt-4", 'state':false, 'value':"cc" , 'label':"No One... Yet!" }],
    colorDict: null,
    clickedTab2: false,
    backup_link: "https://cuboulder.qualtrics.com/jfe/form/SV_414TFHnCqhPcRhQ"

  }),
  watch: {
    visualizationTab(newTab){
        if (newTab == "2"){
            this.clickedTab2 = true
        }
        
    }
  },
  async created() {
    const windowWidth = window.innerWidth
    this.simWidth = Math.min(windowWidth - 10,800)
    },
    mounted() {
        const sumSumCols = () => {
        const windowWidth = window.innerWidth
        this.simWidth = Math.min(windowWidth - 10,800)
        this.rankWidth = Math.min(windowWidth,800)
        this.rankHeight = Math.min(680,window.innerHeight - 100)
    }
    sumSumCols()
    window.addEventListener("resize", sumSumCols);
    if (this.playWrapped){
        this.scroll()
        this.isPlaying = true
    }
    this.viewResults()
    },
  methods: {
    async addInvite (e) {
        if (e){
            e.preventDefault()
            document.getElementById("invite").blur()
        }

        await this.$refs.form.validate()
        if (this.valid){
            const path = process.env.VUE_APP_BASE_URL+'/emailinvite';
            const payload = {invite: this.invite, email: this.email, first:"", last:"", context: "share"};
            await axios.post(path, payload)
                .then((res) => {
                    this.$emit('update:invited', res.data.invited)
                        this.inviteStatus = res.data.message
                        this.showStatus = true
                        setTimeout(() => {this.showStatus = false; this.$refs.form.reset();}, 1500)
                })
            }
        },
    async viewResults() {
        const path = process.env.VUE_APP_BASE_URL+'/usekey';
        const payload = {code: this.code, key: this.key};
        await axios.post(path, payload)
            .then((res) => {
                if (res.data.knowns.length > 0){
                    this.$emit('update:shared', res.data.shared)
                    this.$nextTick(() => {
                        this.other = [res.data.knowns, res.data.preferences,
                                    res.data.ranking, res.data.bounds,
                                    "("+res.data.name+")", "#212121"]
                        this.vizData = res.data.vizData
                        this.colorDict =  res.data.color_dict
                        this.otherExists = true
                    })                    
                } else {
                    this.key = "";
                    this.other[4] = "(_____)"
                    this.vizData = res.data.vizData
                    this.colorDict =  res.data.color_dict
                    this.$nextTick(() => {
                        this.otherExists = false
                    })
                    
                }
            })
        },
    changeWrappedState(x){
        this.isPlaying = x
        this.$emit('update:playWrapped', x)
    },
    endWrap(){
        if (this.justFinished){
            this.$emit('update:navPage', true)
            this.$emit('update:justFinished', false)
        }
        
    },
    pageChange (i) {
        this.$emit('page-change', i)
    },
    clicked (s){
        this.selected = s
    },
    delay(ms){ return new Promise(res => setTimeout(res, ms))},
    async copyLink(){
        let url = window.location.origin + '?r='+this.invite
        navigator.clipboard.writeText(url)
        this.copyIcon = "mdi-check"
        await this.delay(2000).then(() => {
            this.copyIcon = "mdi-content-copy"
        })

    },
    scroll() {
        window.scrollTo(0,0);
    },
  },
}
</script> 

<style>

.v-field__input{
    padding-bottom: 0
}

.small-radio .v-label {
  font-size: 14px;
}

.small-radio i {
    font-size: 18px
}

.downloadButton {
    position: absolute;
    top: 85px;
    left: 775px;

}

.downloadLogo {
    position: absolute;
    top: 2px;
    left: 2px;
}

.downloadTitle {
    position: absolute;
    top: 6px;
    left: 40px;
}

.coverCard1 {
    position: absolute;
    top: 0px;
    left: 0px;
}

.rankSwitch {
    position: absolute;
    top: 55px;
    left: -19px;
    z-index: 2;
}

.rankLabel {
    position: absolute;
    top: 15px;
    left: 10px;
    font-size: 14px;
    min-width: 100px;
}

.mapTools {
    position: absolute;
    top: -97px;
    left: -170px;
}

.mapTools2 {
    margin-left: 6px;
    margin-top: 3px;
    font-size: 14px;


}
</style>