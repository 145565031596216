<template>
<v-row class="" justify="center" no-gutters v-if="preferences.length === 0 && splash === true">
  <v-col cols="12" class="pt-4 ma-0" align="center">
  <v-card elevation="0" height="160px">
    Now, you will compare the venues in which you would publish.
    <br><br>
    <v-btn class="mt-2" 
            width="150px"
            color="blue"
            variant="tonal"
            @click="splash = false">continue</v-btn>
    </v-card>
  </v-col>
</v-row>

<v-row class="" justify="center" no-gutters v-else>

    <v-col cols="12" class="px-2 ma-0">
    <v-card elevation="0">

    <h2 class="text-center pt-4 mb-0">
    {{headers[compareState]}}
    </h2>
    <p v-if="!compareState" class="text-center"><i>(in a world where a paper of your choosing appears in one venue or the other)</i></p>
    </v-card>
    </v-col>
        <v-col v-if="!compareState" cols="12" class="pa-0 mt-3" justify="center" align="center">
        <v-card :class="leftClass" elevation="0">
          <v-icon class="mr-0 arrow" color="blue-grey-darken-1" size="small" v-if="compareState != 1">
                mdi-arrow-left-box
            </v-icon>
        <v-btn
            v-ripple="{ class: `text-blue-grey-lighten-4` }"
            id="left"
            ref="leftJ"
            min-width="150px"
            min-height="150"               
            variant="outlined"
            :disabled="!pair[0].valid"
            @click="submitCompare(pair[0].id)"
            :class="{'disable-events': submitting}" color="blue-grey-darken-1">
            <span style="white-space: normal;">
             {{pair[0].name}}
            </span>
        </v-btn>
        
        <v-card width="32px" class="d-flex align-center justify-center" elevation="0">
            <h3 v-if="pair[1].valid"> or
                </h3>
        </v-card>

        <v-btn
            v-ripple="{ class: `text-blue-grey-lighten-4` }"
            id="right"
            ref="rightJ"
            min-width="150px"
            min-height="150"    
            variant="outlined"
            :disabled="!pair[1].valid"
            @click="submitCompare(pair[1].id)"
            :class="{'disable-events': submitting}" color="blue-grey-darken-1">
            <span style="white-space: normal;">
             {{pair[1].name}}
            </span>
        </v-btn>
        
        <v-icon class="ml-0 arrow" color="blue-grey-darken-1" size="small" v-if="compareState != 1">
                mdi-arrow-right-box
            </v-icon>
        </v-card>
        </v-col>

        <v-col v-if="!compareState" cols="12" class="mt-n0 mb-2" justify="center" align="center">
        <v-btn id="ind" @click="submitCompare(null)" 
            variant="outlined"
            :disabled="!pair[1].valid"
            color="blue-grey-lighten-2" :class="{'disable-events': submitting}">
            Indifferent
        </v-btn>
        
        </v-col> 

        <v-col v-if="!compareState" cols="12" class="pb-2 text-center" style="font-size: 1em">
          <v-btn
            size="small"
            variant="outlined"
            color="blue-grey-lighten-2"
            :disabled="lastPair === null"
            @click="undoLast()"
            >
            undo
          </v-btn>
        </v-col>

        <v-col cols="12 d-flex justify-center">
            <v-card v-show="!dialog" id="visualize" class="mx-0 ma-0 text-center" :width="simWidth" 
                :height="simHeight" variant="text">
                <CompareJournalsSim
                :simWidth="simWidth"
                :simHeight="simHeight"
                :knowns="knowns"
                :preferences="preferences"
                :selected="pair"
                :completed="completed"
                />
            </v-card>
        </v-col>

        <v-col class="px-2 pb-2" v-show="dialog">
        <v-divider></v-divider>
        <v-card elevation="0">
        <v-card-title class="text-h5 grey lighten-2">
          Well done!
        </v-card-title>
        <v-card-text>
          We've learned a lot from your responses... and you've unlocked the final stage! 
          You can make more comparisons later, so click continue to see what's next. 

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue"
            variant="tonal"
            :disabled="loading"
            @click="completeSurvey()"
          >
            continue
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
        </v-col>
</v-row>
</template>


<script>
import axios from 'axios';
import CompareJournalsSim from '../CompareJournalsSim.vue'

export default {
        components: {
        CompareJournalsSim
    },
    props: ["code","invite", "completed", "pgRules", "knowns",
            "unknowns", "preferences", "minCompared", "pair", "dialog2"],
  data: () => ({
    headers: ["Where would you prefer to publish?", 
    "All n choose 2... Nice!"],
    simWidth: 686,
    simHeight: 350,
    rightClass: "mt-0 mb-2 ml-0 mr-0 d-flex justify-center",
    leftClass: "mt-0 mb-2 ml-0 mr-0 d-flex justify-center",
    lockIcon: "mdi-lock",
    compareState: 0,
    dialog: false,
    submitting: false,
    splash: false,
    copyIcon: "mdi-content-copy",
    loading: false,
    lastPayload: null,
    lastPair: null,
  }),
  async created() {
    this.scroll()
    window.addEventListener("keydown", this.discoverListener);
    this.canShare = this.preferences.length >= Math.min(2*this.knowns.length,this.knowns.length*(this.knowns.length-1)/2)
    this.$emit('update:canShare', this.canShare)
    this.lockIcon = this.canShare ? "mdi-lock-open" : "mdi-lock-open"
    this.compareState = this.pair[0].valid ? 0 : 1 
    this.dialog = this.completed ? false : !this.pair[0].valid ? true : this.canShare
    this.splash = false
    },
  mounted(){
    this.simWidth = Math.min(window.innerWidth,686)
    window.addEventListener("resize", () => {this.simWidth = Math.min(window.innerWidth,686);})
    window.addEventListener("beforeunload", this.leavingPage);
  },
  beforeUnmount(){
    this.submitCompare(null,false)
  },
  unmounted() {
    window.removeEventListener("keydown", this.discoverListener);
    window.removeEventListener("beforeunload", this.leavingPage);
    },
    watch: {
      dialog2: function(n){
          this.dialog = n
      }
    },
  methods: {
    leavingPage(event){
      event.preventDefault();
      this.submitCompare(null, false)
      event.returnValue = true;
    },
    undoLast(){
        this.$emit('update:pair', this.lastPair)
        this.lastPair = null
        this.lastPayload = null
        this.$emit('update-progress',2,-1)
    },
    async submitCompare (journalID, staying = true) {
      this.submitting = true
        const path = process.env.VUE_APP_BASE_URL+'/addcomparison';
        const payload = {code: this.code, 
                         i: null, 
                         j: null,
                         tie: false,
                         submit: true};

      if (journalID == null){
        payload.i = this.pair[0].id
        payload.j = this.pair[1].id
        payload.tie = true
      } else {
        payload.i = journalID
        payload.j = journalID == this.pair[0].id ? this.pair[1].id : this.pair[0].id
      }
      await axios.post(path, this.lastPayload === null ? {code: this.code,extra_i: payload.i, extra_j:payload.j,extra_tie:payload.tie,
                                                          submit: false} : {...this.lastPayload, ...{extra_i: payload.i, extra_j:payload.j, extra_tie:payload.tie}})
            .then((res) => {
                if (staying){
                this.lastPair = this.pair
                this.lastPayload = payload
                this.$emit('update:preferences', res.data.preferences)
                this.$emit('update:minCompared', res.data.minCompared)
                this.$emit('update:pair', res.data.pair)
                this.$emit('update-progress',2,1)
                this.$nextTick(() => {
                    this.canShare = this.preferences.length >= Math.min(2*this.knowns.length,this.knowns.length*(this.knowns.length-1)/2)
                    this.$emit('update:canShare', this.canShare)
                    this.compareState = this.pair[0].valid ? 0 : 1
                    this.dialog = this.completed ? false : (!this.pair[0].valid) ? true : this.canShare

                    document.getElementById("left").blur()
                    document.getElementById("right").blur()
                    document.getElementById("ind").blur()
                    this.submitting = false
                })
              } else {
                this.lastPair = null
                this.lastPayload = null
              }
            })
    },
    pageChange (i) {
        this.$emit('page-change', i)
    },
    completeSurvey () {
        this.loading = true
        this.$emit('complete-survey')
    },
    discoverListener(event) {
        if (event.key === "ArrowLeft" && !this.submitting && !this.splash && this.compareState != 1) {
            this.ripple(this.$refs.leftJ.$el)
            this.$refs.leftJ.$el.click()
        } else if (event.key === "ArrowRight" && !this.submitting && !this.splash && this.compareState != 1) {
            this.ripple(this.$refs.rightJ.$el)
            this.$refs.rightJ.$el.click()
        } 
    },
    ripple($el) {
        let ev = new Event("mousedown")
        let offset = $el.getBoundingClientRect()
        ev.clientX = offset.left + offset.width/2
        ev.clientY = offset.top + offset.height/2
        $el.dispatchEvent(ev)

        setTimeout(function() {
            $el.dispatchEvent(new Event("mouseup"))
        }, 10)
    },
    delay(ms){ return new Promise(res => setTimeout(res, ms))},
    async copyLink(){
        let url = window.location.origin + '?r='+this.invite
        navigator.clipboard.writeText(url)
        this.copyIcon = "mdi-check"
        await this.delay(2000).then(() => {
            this.copyIcon = "mdi-content-copy"
        })

    },
    scroll() {
        window.scrollTo(0,0);
    },
  },
}
</script> 

<style>
.disable-events {
  pointer-events: none;
}


  #left {
  width: 45vw; 
  max-width:300px;
  margin-left: -24px;
}
#right {
  width: 45vw; 
  max-width:300px;
  margin-right: -24px;
}
.arrow {
  margin-top: 127px
}

</style>