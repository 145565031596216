<template>
<div>
    <svg class="svg simulation"  :height="rankHeight"
        :width="Math.min(700,Math.max(375,fullWidth - 110))" transform="translate(0,0)">
        <defs x="0" y="0" width="100%" height="100%"></defs>
        <g id="arcs">
        </g>
        <g id="journals">
        </g>
    </svg>
</div>
</template>

<script>
import * as d3 from 'd3';

export default {
    props: ['rankHeight', 'fullWidth', 'knowns', 'preferences', 'ranking', 'bounds', 'selected', 'vizData', 'overallRanking', 'rankView'],
    data: () =>({
        sliding: null,
        nodes: {},
        nodeValues: [], 
        nodeRadius: 10,
        numNodes: 0,
        compared: null,
        markerProps: [[0,0],[0,20],[20,10]],
        lastSelected: -1,
    }),
    async created() {
        this.overallBounds = [Math.min(...Object.values(this.overallRanking).map(c => c.rank)),
                              Math.max(...Object.values(this.overallRanking).map(c => c.rank))]

        this.rankWidth = 240.5
        this.compared = [... new Set([].concat(this.preferences.filter(x => !x[2]).map(x => [x[0],x[1]]).flat(2)))]
        this.initializeSim()
        await this.drawRanking() 
        if (this.fullWidth < 800){
        d3.select('svg').append('text').attr("y",.15*this.rankHeight)
            .attr("x", this.rankWidth).attr("class","default").style("visibility", "visible")
            .append("tspan").attr("x", this.rankWidth).attr("dy", 0 + "em").text("tap to").attr("text-anchor", "start")
            .append("tspan").attr("x", this.rankWidth).attr("dy", 1.2 + "em").text("explore").attr("text-anchor", "start")
        }
        
    },
    watch: {
        ranking(){this.drawRanking() 
        },
        fullWidth(){this.drawRanking(); this.sliding.nodes(this.nodeValues).alpha(1).restart()},
        rankView(){
            for (let i=0;i<this.nodeValues.length;i++){
                this.nodeValues[i].y2 = this.nodeValues[i].y
                this.nodes[this.nodeValues[i].id].y2 = this.nodeValues[i].y
            }
            this.sliding.nodes(this.nodeValues).alpha(1).restart()
        },
        vizData(){ 
            if (this.bounds){
                this.drawRanking()
            }
        },
        selected(){ 
            var that = this
            for (let i=0;i<this.nodeValues.length;i++){
                if (this.nodeValues[i].id == that.selected['id']){
                    this.nodeValues[i].r = 1.2*this.nodeRadius
                    this.nodes[this.nodeValues[i].id].r = 1.2*this.nodeRadius
                } else{
                    this.nodeValues[i].r = this.nodeRadius
                    this.nodes[this.nodeValues[i].id].r = this.nodeRadius
                }
            }
            d3.select('#journals')                                          
                .selectAll("path")
                .data(this.nodeValues, d => d.id)
                .join("path")
                .filter(function(d) { return (d.id  == that.selected['id']) || (d.id  == that.lastSelected)})
                    .attr("d", function(d){
                        return that.drawPath(d,d.id  == that.selected['id'] ? 1 : 1)
                    })

            if (!this.selected.valid){
                d3.select('#arcs')
                  .selectAll("path").interrupt()
                  .style("stroke-width", ".5px")
                  .style("stroke", "#9E9E9E")
                  .style("opacity",.2)
                  .attr('marker-end',null)

                d3.select('#arcs')
                  .selectAll("path").transition().duration(1000)
                  .style("stroke-width", ".5px")
                  .style("stroke", "#9E9E9E")
                  .style("opacity",.4)

                d3.select('#journals')                                          
                .selectAll("text")
                .data(that.nodeValues, d => d.id)
                .join("text").interrupt().transition().duration(1000)
                .style("fill-opacity", that.fullWidth < 800 ? 0 : 1)
                .style("font-size", that.fullWidth < 800 ? 12 : Math.min(16,2.4*that.nodeRadius)+"px")
            }
            else {
                let aWin = this.preferences.filter(x => 
                                x[0] == that.selected['id']).map(x =>
                                                            "#a" + x[0]+"b"+x[1])
                let aLoss = this.preferences.filter(x =>
                                x[1] == that.selected['id']).map(x =>
                                                            "#a" + x[0]+"b"+x[1])

                let name = that.selected['name']

                d3.select('#journals')                                          
                .selectAll("text")
                .data(that.nodeValues, d => d.id)
                .join("text").interrupt().transition().duration(100)
                .style("fill-opacity",d => d.name == name ? 1 : that.fullWidth < 800 ? 0 : .4)
                .style("font-size", d => that.fullWidth < 800 ? 12 : d.name == name ? Math.min(18,2.8*that.nodeRadius)+"px" : Math.min(16,2.4*that.nodeRadius)+"px")

                d3.select('#arcs')
                    .selectAll("path").interrupt().transition().duration(200)
                    .style("stroke-width", ".5px")
                    .style("stroke", "#9E9E9E")
                    .style("opacity",.2)
                    .attr('marker-end',null)

                
                for (let i=0;i<aWin.length;i++){
                    d3.select('#arcs').select(aWin[i]).interrupt() 
                        .style("stroke-width", ".8px")
                        .style("stroke", "#9E9E9E") 
                        .style('opacity', 1)
                        .attr("marker-end", null)
                        .attr('marker-end', 'url(#arrow)')
                } 
                for (let i=0;i<aLoss.length;i++){
                    
                    d3.select('#arcs').select(aLoss[i]).interrupt() 
                        .style("stroke-width", ".8px")
                        .style("stroke", "#9E9E9E")
                        .style('opacity', 1)
                        .attr("marker-end", null)
                        .attr('marker-end', 'url(#arrow)')
                }   
            }
        that.lastSelected = that.selected['id']

        d3.select('#arcs')
                .selectAll("path")
                .attr("d", function(d){
                    var wy = that.nodes[d[0]].y
                    var ly = that.nodes[d[1]].y
                    const side = ly - wy
                    var x0 = side > 0 ? that.nodes[d[0]].tx + that.nodes[d[0]].r/Math.sqrt(2): that.nodes[d[0]].tx - that.nodes[d[0]].r/Math.sqrt(2)
                    var x1 = side > 0 ? that.nodes[d[1]].tx + that.nodes[d[1]].r/Math.sqrt(2): that.nodes[d[1]].tx - that.nodes[d[1]].r/Math.sqrt(2)

                    var y0 = side > 0 ? wy + that.nodes[d[0]].r/Math.sqrt(2) : wy - that.nodes[d[0]].r/Math.sqrt(2)
                    var y1 = side > 0 ? ly - that.nodes[d[1]].r/Math.sqrt(2) : ly + that.nodes[d[1]].r/Math.sqrt(2)

                    return "M"+x0+
                            ","+y0+
                            "Q"+(that.rankWidth/2 + that.rankWidth*side/that.rankHeight)+","+(wy + side/3)+","+
                            x1+","+y1
                })
        }
    },
    unmounted() {
        this.sliding.stop()
    },
    methods: {
        drawPath(d, boost = 1){
            let radius = boost * d.r
            var vizDataMap = []
            if (this.vizData.kk){
                vizDataMap = this.vizData.kk.map(x => x[0])
            }
            if (vizDataMap.includes(d.id)){
                let sr = radius*.625
                return " m -"+sr+", 0 a "+sr+","+sr+" 0 1,0 "+2*sr+",0 a "+sr+","+sr+" 0 1,0 -"+2*sr+",0" + "M0,0"+ " m -"+radius+", 0 a "+radius+","+radius+" 0 1,0 "+2*radius+",0 a "+radius+","+radius+" 0 1,0 -"+2*radius+",0"

            } else {

                return " m -"+radius+", 0 a "+radius+","+radius+" 0 1,0 "+2*radius+",0 a "+radius+","+radius+" 0 1,0 -"+2*radius+",0"

            }
            
        },
        computeY(rank, order, bounds){
            const y = (bounds[1] - rank) / (bounds[1] - bounds[0])
            var ty = (y*(this.rankHeight- 2.3*this.nodeRadius*(this.knowns.length) - 2)+
                (2.3*this.nodeRadius)*(.5 + order)) + 1
            return ty  
        },
        readJournals(journal){
                const tx = this.rankWidth/2
                var ty = this.computeY(this.ranking[journal[0]].rank, this.ranking[journal[0]].order, this.bounds)
                var ty2 = this.computeY(this.overallRanking[journal[0]].rank, this.overallRanking[journal[0]].order, this.overallBounds)
                this.nodes[journal[0]] = {'id':journal[0], 'name':journal[1], 'color':journal[4],
                'tx':tx, 'ty':ty, 'ty2':ty2, 'y2': this.rankView == 0 ? ty : ty2,
                'x': tx, 'y': ty, 'r': this.nodeRadius,
                 'opacity': 1,'order':this.ranking[journal[0]].norder+1}
                this.numNodes ++
        },
        async addNodes(){
            this.numNodes = 0
            let targetRadius = this.rankHeight / 2 / 2 / this.knowns.length
            this.nodeRadius =  Math.max(1, Math.min(10, targetRadius))


            for (let i = 0; i < this.knowns.length; i++) {
                if (this.compared.includes(this.knowns[i][0])){
                    this.readJournals(this.knowns[i])
                }
            }                               
            this.nodeValues = Object.values(this.nodes)

        },
        async initializeSim(){
            var that = this
            this.sliding = d3.forceSimulation()
                            .alphaMin(0.1)

            this.sliding.on("tick", function(){

                        d3.select('#journals').selectAll('path')
                            .attr('transform', function(d) {
                                var a = that.sliding.alpha()
                                if (that.rankView == 0){
                                    d.y = d.ty*(1-a +.1)+d.y2*(a-.1)
                                } else {
                                    d.y = d.ty2*(1-a +.1)+d.y2*(a-.1)
                                }
                                    return "translate("+d.tx+','+(
                                        d.y
                                        )+')'
                                })
                        d3.select('#arcs')
                            .selectAll("path")
                            .attr("d", function(d){
                                var wy = that.nodes[d[0]].y
                                var ly = that.nodes[d[1]].y
                                const side = ly - wy
                                var x0 = side > 0 ? that.nodes[d[0]].tx + that.nodes[d[0]].r/Math.sqrt(2): that.nodes[d[0]].tx - that.nodes[d[0]].r/Math.sqrt(2)
                                var x1 = side > 0 ? that.nodes[d[1]].tx + that.nodes[d[1]].r/Math.sqrt(2): that.nodes[d[1]].tx - that.nodes[d[1]].r/Math.sqrt(2)

                                var y0 = side > 0 ? wy + that.nodes[d[0]].r/Math.sqrt(2) : wy - that.nodes[d[0]].r/Math.sqrt(2)
                                var y1 = side > 0 ? ly - that.nodes[d[1]].r/Math.sqrt(2) : ly + that.nodes[d[1]].r/Math.sqrt(2)

                                return "M"+x0+
                                        ","+y0+
                                        "Q"+(that.rankWidth/2 + that.rankWidth*side/that.rankHeight)+","+(wy + side/3)+","+
                                        x1+","+y1
                            })

                        let select_arcs = d3.select('#arcs')
                            .selectAll("path")
                            .filter(d => 0 > that.nodes[d[0]].y - that.nodes[d[1]].y)
                        
                        let max_arc = Math.max(...select_arcs._groups[0].map(x => x.getBoundingClientRect().width))

                        d3.select('#journals').selectAll('text')
                            .attr('y', function(d) {
                                return that.fullWidth < 800 ? .15*that.rankHeight + 2*d.r*d.order : d.y + .55*that.nodeRadius 
                                })
                            .attr('x',d => that.fullWidth < 800 ? d.tx + max_arc + 15 : d.tx + max_arc + 20)
                            .selectAll('tspan').attr('x',d => that.fullWidth < 800 ? d.tx + max_arc + 15 : d.tx + max_arc + 20 )
                        });
        },
        clickDiscoverNode(n){
            let d = this.nodes[n.id]; 
            this.$emit('clicked', {'id': d.id, 'name':d.name, 'valid':true})
        },
        async drawRanking() {
            this.sliding.stop()
            this.nodes = {}
            this.nodeValues = []
            var that = this
            await this.addNodes() 

            var vizDataMap = []
            if (that.vizData.kk){
                vizDataMap = that.vizData.kk.map(x => x[0])
            }

            d3.select('defs')
            .append('marker')
            .attr('id', 'arrow')
            .attr('viewBox', [0, 0, 30, 30])
            .attr('refX', 0)
            .attr('refY', 0)
            .attr("style", "overflow: visible")
            .attr("x",0)
            .attr("y",0)
            .attr("width",10)
            .attr("height",10)
            .attr('markerWidth', that.nodeRadius)
            .attr('markerHeight', that.nodeRadius)
            .attr('orient', 'auto-start-reverse')
            .attr("markerUnits","userSpaceOnUse")
            .append('path')
            .attr("x",0)
            .attr("y",0)
            .attr("width",'100%')
            .attr("height",'100%')
            .attr('d',"M 0,0 L-10,-6 M 0,0 L-10,6 L-10,-6")
            .style("opacity",.8)
            .style("stroke-width", "2px")
            .attr('stroke', 'black')
            .attr("fill",'black');
            
            d3.select('#arcs')
                .selectAll("path")
                .data(this.preferences.filter(x => !x[2]), d => 'a'+d[0]+'b'+d[1])
                .join("path")
                .attr("id", function(d){return 'a'+d[0]+'b'+d[1]})
                .style("fill", "none")
                .style("stroke-width", ".5px")
                .style("stroke", "#9E9E9E")
                .style("opacity",.4)

            d3.select('#journals')                                          
                .selectAll("path")
                .data(this.nodeValues, d => d.id)
                .join("path")
                .attr("id",function(d){return d.id})
                .attr("d", function(d){
                    return that.drawPath(d)
                })
                .attr("transform", d => "translate("+d.tx+","+d.ty+")")
                .attr("r", function(d){return d.r})
                .style("fill", function(d){return vizDataMap.includes(d.id) ? "url(#kk"+d.color.replace(/[^a-zA-Z\d]/gm,"")+")" : d.color})
                .style("fill-opacity", function(){return .45})
                .style("stroke", function(d){return d.color})
                .style("stroke-width", function(){return .8})
                .style("stroke-opacity", function(){return .85})
                .on("mouseover", function() {d3.selectAll('.default').style("visibility", "hidden"); 
                                              return that.clickDiscoverNode(this)})				
                .on("mouseout", function() {	
                    that.$emit('clicked', {"name": "", "valid": false})	
                })
            
            let select_arcs = d3.select('#arcs')
                            .selectAll("path")
                            .filter(d => 0 > that.nodes[d[0]].ty - that.nodes[d[1]].ty)
            let max_arc = Math.max(...select_arcs._groups[0].map(x => x.getBoundingClientRect().width))

            if (that.fullWidth >= 800){
            d3.selectAll('.default').style("visibility", "hidden")
            d3.select('#journals')                                          
                .selectAll("text")
                .data(this.nodeValues, d => d.id)
                .join("text")
                    .attr("text-anchor", "start")
                    .attr("y", d => d.ty + .55*that.nodeRadius)
                    .attr("x", d => d.tx + max_arc + 20)
                    .style("font-size", Math.min(16,2.4*that.nodeRadius)+"px") 
                    .style("fill-opacity",1)
                    .text(d => d.name.length > 50 ? d.order + ". " + d.name.slice(0,50) + "..." : d.order + ". " + d.name)
                    .on("mouseover", function() {
                        let name = d3.select(this)._groups[0][0].__data__.name
                        that.clickDiscoverNode(
                            d3.select('#journals')
                                .selectAll("path")
                                .filter(d => d.name == name)._groups[0][0]
                        )
                        
                    })				
                    .on("mouseout", function() {	
                        that.$emit('clicked', {"name": "", "valid": false})	
                    })
            } else {
                d3.select('#journals')                                          
                .selectAll("text")
                .data(this.nodeValues, d => d.id)
                .join('text')
                        .attr("text-anchor", "start")
                        .attr("y", .15*that.rankHeight)
                        .attr("x", d => d.tx + max_arc + 5)
                        .attr("dy", 0)
                        .attr("dx", 0)
                        .style("font-size", Math.min(12,2.4*that.nodeRadius)+"px")
                        .style("fill-opacity",0)
                        .on("mouseover", function() {})
                        .on("mouseout", function() {})
                        .call(wrap)
            }
            function wrap(text){ 
                    text.each(function() {

                    var d = d3.select(this)._groups[0][0].__data__
                    var text = d3.select(this)

                    let lines = [d.order + ". "]; 
                    var l = 0
                    let words = d.name.split(' ')
                    var lineLength = 3
                    for (let i = 0;  i < words.length; i++){
                        if (lineLength + words[i].length + 1 > 20){
                            l += 1
                            lineLength = 0
                            lines[l] = ""
                        } 
                        lines[l] = lines[l] + words[i] + " "
                        lineLength += words[i].length + 1
                    }
                    text.text(null).append("tspan").attr("dy", 0 + "em").text(lines[0]).attr("text-anchor", "start")
                    for (let i = 1;  i < lines.length; i++){
                        text.append("tspan").attr("x", d.tx + max_arc + 5 + "px").attr("dy", 1.2 + "em").text(lines[i]).attr("text-anchor", "start")
                    }  
                    })
                }
            this.sliding.nodes(this.nodeValues).alpha(1).restart()
        },

    },
}
</script>