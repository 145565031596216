<template>
<div>
    <svg class="svg simulation"  :height="simHeight" 
        :width="simWidth" transform="translate(0,0)">
        <g id="arcs">
        </g>
        <g id="known">
        </g>
    </svg>
</div>
</template>

<script>
import * as d3 from 'd3';

export default {
    props: ['simHeight', 'simWidth', 'knowns', 'preferences', 'selected', 'completed'],
    data: () =>({
        simulation: null,
        nodes: {},
        nodeValues: [], 
        nodeRadius: 10,
        circleRadius: 80,
        numNodes: 0,
        NToGo: ""
    }),
    async created() {
        await this.drawNodes()
        this.drawArcs()
        this.NToGo = Math.min(2*this.knowns.length,this.knowns.length*(this.knowns.length-1)/2) - this.preferences.length
        
        d3.select('svg').append('text')
        .attr("y", this.simHeight/2)
        .attr("x", this.simWidth/2)
        .attr("class","default").attr("font-size","24px")
        .style("visibility",(!this.completed) && (this.NToGo > 0) && (this.NToGo / Math.min(2*this.knowns.length,this.knowns.length*(this.knowns.length-1)/2)) < .25 ? "visible" : "hidden" )
        .append("tspan")
            .attr("class","default1")
            .attr("x", this.simWidth/2)
            .attr("dy", -1.2 + "em")
            .text(this.NToGo)
            .attr("text-anchor", "middle")
        
        d3.selectAll('.default')
        .append("tspan")
            .attr("class","default2")
            .attr("x", this.simWidth/2)
            .attr("dy", 1.2 + "em")
            .text(this.NToGo == 1 ? "Comparison" : "Comparisons")
            .attr("text-anchor", "middle")

        d3.selectAll('.default')
        .append("tspan")
            .attr("class","default3")
            .attr("x", this.simWidth/2)
            .attr("dy", 1.2 + "em")
            .text("To Go!")
            .attr("text-anchor", "middle")
    },
    watch: {
        preferences(){this.drawArcs();
        this.NToGo = Math.min(2*this.knowns.length,this.knowns.length*(this.knowns.length-1)/2) - this.preferences.length;
        d3.selectAll('.default')
        .style("visibility",(!this.completed) && (this.NToGo > 0) && (this.NToGo / Math.min(2*this.knowns.length,this.knowns.length*(this.knowns.length-1)/2)) < .25 ? "visible" : "hidden" )
        d3.selectAll('.default1')
            .text(this.NToGo)
        d3.selectAll('.default2')
            .text(this.NToGo == 1 ? "Comparison" : "Comparisons")
        },
        selected(){
            this.drawArcs();
            var that = this
            d3.select('#known')                                          
                .selectAll("circle")
                .data(this.nodeValues, d => d.id)
                .join("circle")
                .style("fill-opacity", function(d){return d.id == that.selected[0].id || d.id == that.selected[1].id ? .5 : .2})
                .style("stroke-width", function(d){return d.id == that.selected[0].id || d.id == that.selected[1].id ? 1 : .6})
                .style("stroke-opacity", function(d){return d.id == that.selected[0].id || d.id == that.selected[1].id ? 1 : .3})

        },
        async simWidth(){
            await this.drawNodes()
            this.drawArcs()
        }
    },
    methods: {
        readJournals(journal){
                var rotation = 2*Math.PI*(this.numNodes)/this.knowns.length
                const tx = (this.circleRadius * Math.cos(rotation)) + this.simWidth/2
                const ty = (this.circleRadius * Math.sin(rotation)) + this.simHeight/2
                this.nodes[journal[0]] = {'id':journal[0], 'name':journal[1], 'color':journal[4],
                'rotation': rotation,
                'fx': tx, 'fy': ty, 'r': this.nodeRadius, 'fillOpacity': .45, 'edgeOpacity':.85}
                this.numNodes ++
        },
        async addNodes(){
            this.numNodes = 0
            this.circleRadius = 1*Math.min(this.simWidth-2.2*this.nodeRadius,
                                           this.simHeight-2.2*this.nodeRadius) / 2 

            let targetRadius = Math.PI*this.circleRadius/this.knowns.length - 2
            this.nodeRadius =  Math.max(1,Math.min(10, targetRadius)) 

            for (let i = 0; i < this.knowns.length; i++) {
                this.readJournals(this.knowns[i])
            }                               
            this.nodeValues = Object.values(this.nodes)

        },

        async drawNodes() {
            var that = this
            await this.addNodes()     
            d3.select('#known')                                          
                .selectAll("circle")
                .data(this.nodeValues, d => d.id)
                .join("circle")
                .attr("cx", function(d){return d.fx})
                .attr("cy", function(d){return d.fy})
                .attr("r", function(d){return d.r})
                .style("fill", function(d){return d.color})
                .style("stroke", function(d){return d.color})
                .style("fill-opacity", function(d){return d.id == that.selected[0].id || d.id == that.selected[1].id ? .5 : .2})
                .style("stroke-width", function(d){return d.id == that.selected[0].id || d.id == that.selected[1].id ? 1 : .6})
                .style("stroke-opacity", function(d){return d.id == that.selected[0].id || d.id == that.selected[1].id ? 1 : .3})

        },
        drawArcs(){
            var that = this
            let knownIds = this.knowns.map(x => x[0])
            var allPairs = structuredClone(this.preferences)
            if (allPairs.filter(el => (el[0] === that.selected[0].id && el[1] === that.selected[1].id)).length === 0){
                allPairs.push([that.selected[0].id, that.selected[1].id, false])
            }
            
            d3.select('#arcs')
                .selectAll("path")
                .data(allPairs.filter(x => knownIds.includes(x[0]) && knownIds.includes(x[1])),
                      d => 'a'+d[0]+'b'+d[1])
                .join("path")
                .style("fill", "none")
                .style("stroke-width", d => d[0] == that.selected[0].id && d[1] == that.selected[1].id ? 1 : .5)
                .style("stroke", "#9E9E9E")
                .style("opacity",d => d[0] == that.selected[0].id && d[1] == that.selected[1].id ? 1 : .5)
                .attr("d", function(d){
                                let n1 = that.nodes[d[0]]
                                let n2 = that.nodes[d[1]]
                                let cr = (that.circleRadius - that.nodeRadius)
                                let n1xy = [cr * Math.cos(n1.rotation) + that.simWidth/2, cr * Math.sin(n1.rotation) + that.simHeight/2]
                                let n2xy = [cr * Math.cos(n2.rotation) + that.simWidth/2, cr * Math.sin(n2.rotation) + that.simHeight/2]

                                let adj = .5
                                let mx = adj*(n1xy[0] + n2xy[0])/2 + (1-adj)*that.simWidth/2
                                let my = adj*(n1xy[1] + n2xy[1])/2 + (1-adj)*that.simHeight/2
                                return "M"+n1xy[0]+
                                        ","+n1xy[1]+
                                        "Q"+mx+","+my+","+
                                        n2xy[0]+","+n2xy[1]
                            })
        },

    },
}
</script>