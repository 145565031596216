<template>
<div>
    <svg class="svg simulation"  :height="simHeight"
        :width="simWidth" transform="translate(0,0)">
        <g id="venues">
        </g>
        <g id="subjects">
        </g>
        <defs>
        </defs>
    </svg>
    <rect id="svg_simulation" />
</div>
</template>

<script>
import * as d3 from 'd3';

export default {
    props: ['simHeight', 'simWidth', 'selected', "ranking",
            "overallRanking", "vizData", "colorDict", "groups"],
    data: () =>({
        simulation: null,
        nodes: {},
        nodeValues: [], 
        nodeRadius: 10,
        circleRadius: 80,
        numNodes: 0,
        Nrestarts: 0,
        lastSelected: -1,
        colors: [],
        colorCount: [],
        tooltip: null,
        justAdded: [],
    }),
    async created() {
        await this.startDiscoverSimulation()
        this.updateDiscoverSimulation()
        if (this.simWidth < 800){
            d3.select('svg').append('text').attr("y",20)
                .attr("x", 20).attr("class","default").style("visibility", "visible")
                .append("tspan").attr("x", 20).attr("dy", 0 + "em").text("tap to").attr("text-anchor", "start")
                .append("tspan").attr("x", 20).attr("dy", 1.2 + "em").text("explore").attr("text-anchor", "start")
            }
    },
    unmounted() {
        this.simulation.stop()
    },
    watch: {
        async vizData(){ 
            await this.deleteNodes()
            this.updateDiscoverSimulation() 
        },
        groups(){
            this.updateDiscoverSimulation(false)
        },
        async simWidth(){
            await this.updateDiscoverSimulation(true)
            if (this.simWidth >= 800){
                d3.selectAll('.default').style("visibility", "hidden")
            } 
            
        },
        selected(){ 
            var that = this
            d3.select('#venues')                                          
                .selectAll("path")
                .data(this.nodeValues, d => d.id)
                .join("path")
                .filter(function(d) { return (d.id  == that.selected['id']) || (d.id  == that.lastSelected)})
                    .attr("d", function(d){
                        return that.drawPath(d,d.id  == that.selected['id'] ? 1.2 : 1)
                    })
                    .style("fill-opacity",  function(d){if (d.fillOpacity > 0){
                        return d.fillOpacity
                    } else{
                        return 0
                    }})
                    .style("stroke-opacity", function(d){if (d.fillOpacity > 0){
                        return d.edgeOpacity
                    } else{
                        return 0
                    }})

            if (!this.selected.valid){
                d3.select('#venues')
                  .selectAll("path")
                  .data(this.nodeValues, d => d.id)
                  .join("path").interrupt().transition().duration(1000)
                  .style("fill-opacity",  function(d){if (d.fillOpacity > 0){
                        return d.fillOpacity
                    } else{
                        return 0
                    }})
                    .style("stroke-opacity", function(d){if (d.fillOpacity > 0){
                        return d.edgeOpacity
                    } else{
                        return 0
                    }})
            } else {
                d3.select('#venues')                                          
                    .selectAll("path")
                    .data(this.nodeValues, d => d.id)
                    .join("path")
                    .filter(function(d) { return (d.id  == that.selected['id'])}).interrupt()
                        .style("fill-opacity",  function(d){if (d.fillOpacity > 0){
                        return d.fillOpacity
                    } else{
                        return 0
                    }})
                    .style("stroke-opacity", function(d){if (d.fillOpacity > 0){
                        return 1
                    } else{
                        return 0
                    }})
            }
            that.lastSelected = that.selected['id']
        }
    },
    methods: {
        pSBC(p,c0,c1,l) {
            let r,g,b,P,f,t,h,i=parseInt,m=Math.round,a=typeof(c1)=="string";
            if(typeof(p)!="number"||p<-1||p>1||typeof(c0)!="string"||(c0[0]!='r'&&c0[0]!='#')||(c1&&!a))return null;
            if(!this.pSBCr)this.pSBCr=(d)=>{
                let n=d.length,x={};
                if(n>9){
                    [r,g,b,a]=d=d.split(","),n=d.length;
                    if(n<3||n>4)return null;
                    x.r=i(r[3]=="a"?r.slice(5):r.slice(4)),x.g=i(g),x.b=i(b),x.a=a?parseFloat(a):-1
                }else{
                    if(n==8||n==6||n<4)return null;
                    if(n<6)d="#"+d[1]+d[1]+d[2]+d[2]+d[3]+d[3]+(n>4?d[4]+d[4]:"");
                    d=i(d.slice(1),16);
                    if(n==9||n==5)x.r=d>>24&255,x.g=d>>16&255,x.b=d>>8&255,x.a=m((d&255)/0.255)/1000;
                    else x.r=d>>16,x.g=d>>8&255,x.b=d&255,x.a=-1
                }return x};
            h=c0.length>9,h=a?c1.length>9?true:c1=="c"?!h:false:h,f=this.pSBCr(c0),P=p<0,t=c1&&c1!="c"?this.pSBCr(c1):P?{r:0,g:0,b:0,a:-1}:{r:255,g:255,b:255,a:-1},p=P?p*-1:p,P=1-p;
            if(!f||!t)return null;
            if(l)r=m(P*f.r+p*t.r),g=m(P*f.g+p*t.g),b=m(P*f.b+p*t.b);
            else r=m((P*f.r**2+p*t.r**2)**0.5),g=m((P*f.g**2+p*t.g**2)**0.5),b=m((P*f.b**2+p*t.b**2)**0.5);
            a=f.a,t=t.a,f=a>=0||t>=0,a=f?a<0?t:t<0?a:a*P+t*p:0;
            if(h)return"rgb"+(f?"a(":"(")+r+","+g+","+b+(f?","+m(a*1000)/1000:"")+")";
            else return"#"+(4294967296+r*16777216+g*65536+b*256+(f?m(a*255):0)).toString(16).slice(1,f?undefined:-2)
        },

        drawHexagon(radius, rotation=0) {
            var thirdPi = Math.PI / 3,
            angles = [0, thirdPi, 2 * thirdPi, 3 * thirdPi, 4 * thirdPi, 5 * thirdPi].map(x => x + rotation*thirdPi);
            return angles.map(function(angle) {
            var x1 = Math.sin(angle) * radius,
                y1 = -Math.cos(angle) * radius
            return [x1, y1];
            });
        },  
        drawPath(d, boost = 1){
            let radius = boost * d.r
            if (d.k == 'kk'){
                let sr = radius*.625
                return " m -"+sr+", 0 a "+sr+","+sr+" 0 1,0 "+2*sr+",0 a "+sr+","+sr+" 0 1,0 -"+2*sr+",0" + "M0,0"+ " m -"+radius+", 0 a "+radius+","+radius+" 0 1,0 "+2*radius+",0 a "+radius+","+radius+" 0 1,0 -"+2*radius+",0"

            } else if (d.k[0] == 'k'){

                return " m -"+radius+", 0 a "+radius+","+radius+" 0 1,0 "+2*radius+",0 a "+radius+","+radius+" 0 1,0 -"+2*radius+",0"

            } else if (d.k[1] == 'k'){

                return " m -"+radius+", 0 a "+radius+","+radius+" 0 1,0 "+2*radius+",0 a "+radius+","+radius+" 0 1,0 -"+2*radius+",0"

            } else if (d.k[0] == 'c'){
                return " m -"+radius+", 0 a "+radius+","+radius+" 0 1,0 "+2*radius+",0 a "+radius+","+radius+" 0 1,0 -"+2*radius+",0"

            } else {
                return " m -"+"1, 0 a "+"1,"+"1 0 1,0 "+"2,0 a "+"1,"+"1 0 1,0 -"+"2,0"
            }

            
        },
        deleteNodes(){
            var newNodes = []
            for (const journals of Object.values(this.vizData)) {
                for (let i = 0; i < journals.length; i++) {
                    newNodes.push(journals[i][0].toString())
                }
            }
            for (const k of Object.keys(this.nodes)) {
                if (!newNodes.includes(k)){
                    this.numNodes--
                    delete this.nodes[k]
                }
            }
            this.nodeValues = Object.values(this.nodes)
        },
        readJournals(journals, k){
            var rDict = {"kk":1.2,"kc":.6,"ku":.65,"ck":.6,"uk":.4,"cc":.3, "cu":.4,"uc":.1,"uu":.1}
            var oDict = {"kk":.45,"kc":.45,"ku":.5,"ck":0,"uk":.3,"cc":.25, "cu":.3,"uc":.1,"uu":.1}
            var eDict = {"kk":.85,"kc":.85,"ku":.9,"ck":.85,"uk":.4,"cc":.0, "cu":0,"uc":.1,"uu":.1}
            var nJournals = journals.length
            if (k == 'cc'){
                nJournals = Math.max(0, journals.length)
            }
            for (let i = 0; i < nJournals; i++) {
                const journal = journals[i]
                const x = 90 + 10 + (journal[3])*(this.simWidth - 180 - 20) 
                const y = 50 + 10 + (1-journal[2])*(this.simHeight - 100 - 20) 
                if (journal[0] in this.nodes){
                    if (!this.justAdded.includes(journal[0])){                    
                    this.nodes[journal[0]]['k'] = k
                    this.nodes[journal[0]]['r'] = this.nodeRadius * (rDict[k])
                    this.nodes[journal[0]]['fillOpacity'] = oDict[k]
                    this.nodes[journal[0]]['edgeOpacity'] = eDict[k]
                    this.nodes[journal[0]]['r'] = this.nodeRadius * (rDict[k])
                    this.nodes[journal[0]]['tx'] = x
                    }
                }
                else{
                    this.nodes[journal[0]] = {'id':journal[0], 'name':journal[1],
                'vx':0, 'vy':0, 'tx': x, 'ty': y,
                'x': x,
                'y': y, 
                'k': k, 'color':journal[4],'concept':journal[5],
                'r': this.nodeRadius * (rDict[k]),
                'fillOpacity':oDict[k], 'edgeOpacity':eDict[k]}
                this.numNodes ++
                }
                if (!this.groups.includes(k) && !this.justAdded.includes(journal[0])){
                    this.nodes[journal[0]]['fillOpacity'] = 0
                    this.nodes[journal[0]]['edgeOpacity'] = 0
                } else {
                    this.justAdded.push(journal[0])
                }
                if (!(this.colorCount.includes(journal[4]))){
                    this.colorCount.push(journal[4])
                    this.colors.push(['kk', journal[4], 1,1,0])
                    this.colors.push(['ku', journal[4], 1,1,1])
                    this.colors.push(['uk', journal[4], 1,1,1])
                    this.colors.push(['kc', journal[4], 1,1,1])
                    this.colors.push(['ck', journal[4], 1,1,1])
                    this.colors.push(['cu', journal[4], 1,1,1])
                    this.colors.push(['cc', journal[4], 1,1,1])
                    this.colors.push(['uu', journal[4], 1,1,1])
                    this.colors.push(['uc', journal[4], 1,1,1])
                }
            }
        },
        async addNodes(){
            this.circleRadius = 1*Math.min(this.simWidth, this.simHeight) / 2
            this.nodeRadius = 16 
            this.justAdded = []                                                              
            for (const k of ['ku', 'uk', 'cc']){
                this.readJournals(this.vizData[k], k)
            }
            this.nodeValues = Object.values(this.nodes)
            
            
        },

        async startDiscoverSimulation() {
            var that = this

            this.simulation = d3.forceSimulation()
                            .velocityDecay(.6)
                            .alphaMin(0.1)
                            .force("x", d3.forceX(
                                function (d) {return .99*d.tx + .01*that.simWidth/2}
                            ).strength(.1)) 
                            .force("y", d3.forceY(
                                function (d) {return .99*d.ty + .01*that.simHeight/2}
                            ).strength(.1))
                            .force("collide", d3.forceCollide().strength(1)
                                    .radius(d => 1.2*d.r).iterations(3))

            this.simulation.on("tick", function(){             
                        d3.select('#venues').selectAll('path')
                        .attr('transform', function(d) {
                                let borderX = that.simWidth >= 800 ? 90 + d.r : d.r/2
                                let borderY = that.simWidth >= 800 ? 50 + d.r : d.r/2
                                let dx = d.x < that.simWidth-borderX ? Math.max(borderX,d.x) : Math.min(that.simWidth-borderX,d.x); d.x = dx
                                let dy = d.y < that.simHeight-borderY ? Math.max(borderY,d.y) : Math.min(that.simHeight-borderY,d.y); d.y = dy
                                return "translate("+d.x+','+d.y+')'
                              })
                        });
            
        },

        clickDiscoverNode(n){
            let d = this.nodes[n.id]; 
            this.$emit('clicked', {'id': d.id, 'name':d.name, 'valid':true})
        },

        async updateDiscoverSimulation (restart = true){
            await this.addNodes()
            var that = this

            let grads = d3.select('defs').selectAll("radialGradient")
                .data(this.colors)
                .join("radialGradient")
                    .attr('id', function(d){return d[0] +d[1].replace(/[^a-zA-Z\d]/gm,"")})

            grads.append("stop").attr("offset", "0%")
                .style("stop-color", (d) => d[4] == 0 ? "white" : d[1])
                .style("stop-opacity",  d => d[2])
            grads.append("stop").attr("offset", "55%")
                .style("stop-color", (d) => d[4] == 0 ? "white" : d[1])
                .style("stop-opacity",  d => d[2])
            grads.append("stop").attr("offset", "65%")
                .style("stop-color", (d) => d[3] == 0 ? "white" : d[1])
                .style("stop-opacity",  d => d[2])
            let subject_positions = {'Medicine': [120, 10],
                'Biology': [120 + (680-120)/3, 10],
                'Environmental science': [120 + 2*(680-120)/3, 10 - 9],
                'Chemistry': [680, 10],

                'Geology': [760 - 3, 80-14],
                'Materials science': [760 - 3, 200-14 - 9 ],
                'Physics': [760 - 3, 320-14],
                'Engineering': [760 - 3, 440-14],
                'Geography': [760 - 3, 560-14],

                'Mathematics': [80+3*(720-80)/4, 600+9],
                'Computer science': [720, 600],
                'Business': [80+2*(720-80)/4, 600+9],
                'Economics': [80+(720-80)/4, 600+9],
                'Political science': [80, 600],

                'History': [40+1, 560-14],
                'Sociology': [40+1, 440-14],
                'Art': [40+1, 320-14],
                'Philosophy': [40+1, 200-14],
                'Psychology': [40+1, 80-14]}

            subject_positions = {'Medicine': [80+.5*(720-80)/4 + 80-5, 10],
                'Biology': [90+(800-180)/2, 10],
                'Environmental science': [170 + 2*(680-120)/3 + 60-20, 10 - 9],
                'Geology': [760-8, 10+50],

                'Chemistry': [760 - 8, 80-14 + 50],
                'Physics': [760 - 8, 320-14 - 9 -40],
                'Materials science': [760 - 8, 200-14],
                'Engineering': [760 - 8, 440-14 -80],

                'Geography': [760 - 8, 560-14-140],

                'Mathematics': [760 - 8, 600+9-100],
                'Computer science': [760 - 8, 600-150],


                'Business': [80+3*(720-80)/4+20, 600+5],
                'Economics': [80+2.4*(720-80)/4+20, 600+5],
                'Political science': [80+1.81*(720-80)/4+20, 600-4],

                'History': [80+1.3*(720-80)/4+20, 600+5],
                'Art': [80+.92*(720-80)/4+20, 600+5],
                'Philosophy': [80+.5*(720-80)/4+20, 600+5],

                'Sociology': [40+4, 440-14+50],

                'Psychology': [40+4, 80-14+300],
                'Unknown': [40+4, 80-14+200]}
            let colorMap = this.colorDict.map(a => [a[0],a[1], subject_positions[a[0]].map(function(x,index) { return index == 0 ? that.simWidth - x : x })])
            d3.select('#subjects')
                .selectAll("text")
                .data(that.simWidth >= 800 ? colorMap : [], d => d[0])
                .join("text")
                    .attr("y", d => d[2][1])
                    .attr("text-anchor", "middle")
                        .each(function(d) {
                            var arr = d[0].replace("science","Science").split(" ");
                            d3.select(this).selectAll("tspan")
                            .data(arr)
                            .join("tspan")
                            .attr("text-anchor", "middle")
                            .attr("x", d[2][0])
                            .attr("dy", function() {
                                return "1.2em"
                            })
                            .text(String)
                        })
                    .style("fill", (d) => that.pSBC(.1,d[1]))
                    .on("mouseover", function() {
                        d3.selectAll('.default').style("visibility", "hidden")
                        let concept = d3.select(this)._groups[0][0].__data__[0]
                        d3.select('#subjects').selectAll("text").filter(function(){ 
                            return d3.select(this).text().toLowerCase() == concept.replace(/\s/g, '').toLowerCase()
                        }).interrupt().transition().duration(750)
                        .style("font-weight", 900)
                        d3.select('#venues')                                          
                            .selectAll("path")
                            .data(that.nodeValues, d => d.id)
                            .join("path").interrupt().transition().duration(750)
                            .style("fill-opacity",  function(d){return d.concept  == concept ? d.fillOpacity == 0 ? 0 : .7 : Math.min(.15,d.fillOpacity)})
                            .style("stroke-opacity", function(d){return d.concept  == concept ?  d.edgeOpacity == 0 ? 0 : 0 : 0})
                                
                            })
                            .on("mouseout", function() {
                                let concept = d3.select(this)._groups[0][0].__data__[0]
                                d3.select('#subjects').selectAll("text").filter(function(){ 
                                    return d3.select(this).text().toLowerCase() == concept.replace(/\s/g, '').toLowerCase()
                                }).interrupt().transition().duration(750)
                                .style("font-weight", 500)
                                d3.select('#venues')                                          
                                    .selectAll("path")
                                    .data(that.nodeValues, d => d.id)
                                    .join("path").interrupt().transition().duration(750)
                                        .style("fill-opacity",  function(d){return d.fillOpacity})
                                        .style("stroke-opacity", function(d){return d.edgeOpacity})
                            })
            this.tooltip = d3.select("#svg_simulation")
                .style("width","150px")
                .style("text-align","left")
                .style("position", "absolute")
                .style("visibility", "hidden")
                .style("background","#B0BEC5")
                .style("fill-opacity", .1)
                .style("padding", "4px")
                .style("border-radius", "8px")
            d3.select('#venues')                          
                .selectAll("path")
                .data(this.nodeValues, d => d.id)
                .join("path")
                .attr("transform", d => "translate("+d.x+","+d.y+")")
                .attr("d", function(d){
                    return that.drawPath(d)
                })
                .attr("id",function(d){return d.id})
                .style("fill", function(d){return d.color})

                .style("fill-opacity",  function(d){return d.fillOpacity})
                .style("stroke", function(d){return d.color})
                .style("stroke-width", function(d){return ['uk','ck'].includes(d.k) ? .8 : .8})
                .style("stroke-opacity", function(d){return d.edgeOpacity})

                .on("mouseover", function(d) {
                    d3.selectAll('.default').style("visibility", "hidden")
                    let c = d3.select(this)._groups[0][0].__data__
                    if (c.fillOpacity > 0){
                        that.clickDiscoverNode(this)
                        d3.select('#subjects').selectAll("text").filter(function(){ 
                            return d3.select(this).text().toLowerCase() == c.concept.replace(/\s/g, '').toLowerCase()
                        }).interrupt().transition().duration(10)
                        .style("font-weight", 900)

                        that.tooltip.text(d.srcElement.__data__.name)	
                        let th = that.tooltip._groups[0][0].clientHeight
                        return that.tooltip
                            .style("height",th+"px")
                            .style("top", c.y <= that.simHeight/2 ? (c.y+0*c.r)+"px" : (c.y-1.2*c.r - th + 24)+"px")
                            .style("left",c.x <= that.simWidth/2 ? (c.x+1.2*c.r)+"px" : (c.x-1.2*c.r-150)+"px")	
                            .style("background",that.pSBC(.42,c.color)) 
                            .style("border","solid" )
                            .style("border-color", that.pSBC(.0,c.color))
                            .style("border-width","1px")
                            .style("visibility", "visible")	
                            .style("text-align",c.x <= that.simWidth/2 ? "center" : "center")
                    }
                })					
                .on("mouseout", function() {
                    let c = d3.select(this)._groups[0][0].__data__
                    d3.select('#subjects').selectAll("text").filter(function(){ 
                            return d3.select(this).text().toLowerCase() == c.concept.replace(/\s/g, '').toLowerCase()
                        }).interrupt().transition().duration(200)
                                .style("font-weight", 500)
                    if (c.fillOpacity > 0){	
                        that.$emit('clicked', {"name": "", "valid": false})	
                        return that.tooltip.style("visibility", "hidden").style("height",null)
                    }
                })
            if (restart){
                this.simulation.nodes(this.nodeValues).alpha(1).restart()
            }
        },
    },
}
</script>