<template>
<v-row class="" justify="center" no-gutters>
  <v-col cols="12" align="center">
    <v-card class="d-flex flex-column" elevation="0" min-height="650px" :width="simWidth">

    <div v-show="panel == 0">
      <Transition>
      <div v-show="panels[0]['value'] > 0">
      <h2 class="text-center mt-4 mb-0" >
          Thank You and Congratulations!
      </h2>
      </div>
      </Transition>

      <Transition>
      <div v-show="panels[0]['value'] > 10">
      <p class="text-center pt-0 mb-2" v-if="rewrapped[0][0] == (rewrapped[0][1]+1)">
          You are the 
          <b>{{rewrapped[0][0].toLocaleString() + formatPlacement(rewrapped[0][0])}}</b>
          person to complete Publish or Comparish
      </p>

      <p class="text-center pt-0 mb-2" v-else>
          You were the 
          <b>{{rewrapped[0][0].toLocaleString() + formatPlacement(rewrapped[0][0])}}</b>
          person of <b>{{(rewrapped[0][1]+1).toLocaleString()}}</b> people to complete Publish or Comparish
      </p>
      </div>
      </Transition>

      <Transition>
      <div v-show="panels[0]['value'] > 25">
      <v-row align="center">
        <v-col cols="5" align="end">
        <v-progress-circular :model-value="100*(rewrapped[9][0]+1)/(rewrapped[9][1]+1)" size="100" :width="9" :color="colors[1][0]">
          <v-icon v-if="(rewrapped[9][0]+1)/(rewrapped[9][1]+1) >= .5" icon="mdi-rabbit" size="64px" :color="colors[1][1]"></v-icon>
          <v-icon v-else icon="mdi-tortoise" size="64px" :color="colors[1][2]"></v-icon>
        </v-progress-circular>
        </v-col>

        <v-col cols="7" align="start">
        <p>And the <b>{{(rewrapped[9][3]).toLocaleString() + formatPlacement(rewrapped[9][3])}}</b> participant from <b>{{rewrapped[9][2]}}</b>! Now, researchers from your field make up <b>{{parseInt(100*(rewrapped[9][0]+1)/(rewrapped[9][1]+1))}}%</b> of all participants.</p>

        </v-col>
      </v-row>
      </div>
      </Transition>

      <Transition>
      <div v-show="panels[0]['value'] > 60">
      <v-row align="center">
        <v-col cols="5" align="end">
        <v-progress-circular :model-value="100*rewrapped[1][0]/rewrapped[0][1]" size="100" :width="9" :color="colors[0][0]">
          <v-icon v-if="rewrapped[1][0]/rewrapped[0][1] > .5" icon="mdi-telescope" size="64px" :color="colors[0][1]"></v-icon>
          <v-icon v-else icon="mdi-microscope" size="64px"  :color="colors[0][2]"></v-icon>
        </v-progress-circular>
        </v-col>
        
        <v-col cols="7" align="start">
        <p>You discovered <b>{{rewrapped[1][1]}}</b> places you’d publish,</p> 
        <p>more than discovered by <b>{{parseInt(100*rewrapped[1][0]/rewrapped[0][1])}}%</b> of other participants.</p>
        </v-col>
      </v-row>
      </div>
      </Transition>
    </div>

    <div v-show="panel == 1">
      <v-row justify="center">

        <v-col cols="5" align="center">
        <Transition>
        <div v-if="panels[1]['value'] > 2">
          <h4 class="pt-4">Of the places you'd publish...</h4>
        </div>
        </Transition>
        </v-col>
        <v-col cols="5" align="center">
        <Transition>
        <div v-if="panels[1]['value'] > 42">
          <h4 class="pt-4">Of the places others would...</h4>
        </div>
        </Transition>
        </v-col>
        
        <v-col cols="5" align="center">
        <Transition>
        <div v-if="panels[1]['value'] > 10">
          <p class="pb-2">{{parseInt(100*rewrapped[6][0][0]/rewrapped[6][0][1])}}% are Open Access</p>
          <v-progress-circular :model-value="100*rewrapped[6][0][0]/rewrapped[6][0][1]" :width="pieRadius/2" :size="pieRadius" :color="colors[2][1]"></v-progress-circular>
        </div>
        </Transition>
        </v-col>
        <v-col cols="5" align="center">
        <Transition>
        <div v-if="panels[1]['value'] > 50">
          <p class="pb-2">{{parseInt(100*rewrapped[6][1][0]/rewrapped[6][1][1])}}% are Open Access</p>
          <v-progress-circular :model-value="100*rewrapped[6][1][0]/rewrapped[6][1][1]" :width="pieRadius/2" :size="pieRadius" :color="colors[2][2]"></v-progress-circular>
        </div>
        </Transition>
        </v-col>

        <v-col cols="5" align="center">
        <Transition>
        <div v-if="panels[1]['value'] > 25">
          <p class="pb-2">{{parseInt(100*rewrapped[7][0][0][0][1]/rewrapped[7][0][1])}}% are hosted in {{regionRename(regionNames.of(rewrapped[7][0][0][0][0]))}}</p>
          <v-progress-circular :model-value="100*rewrapped[7][0][0][0][1]/rewrapped[7][0][1]" :width="pieRadius/2" :size="pieRadius" :color="colors[3][1]"></v-progress-circular>
        </div>
        </Transition>
        </v-col>      
        <v-col cols="5" align="center">
        <Transition>
        <div v-if="panels[1]['value'] > 65">
          <p class="pb-2">{{parseInt(100*rewrapped[7][1][0][rewrapped[7][0][0][0][0]]/rewrapped[7][1][1])}}% are hosted in {{regionRename(regionNames.of(rewrapped[7][0][0][0][0]))}}</p>
          <v-progress-circular :model-value="100*rewrapped[7][1][0][rewrapped[7][0][0][0][0]]/rewrapped[7][1][1]" :width="pieRadius/2" :size="pieRadius" :color="colors[3][2]"></v-progress-circular>
        </div>
        </Transition>
        </v-col>

      </v-row>
    </div>

    <div v-show="panel == 2">
      <Transition>
      <div v-if="panels[2]['value'] > 5">
      <v-row align="center" class="mt-4">
        <v-col cols="4" sm="5" align="end">
        <img v-if="rewrapped[8][0][0][1] / rewrapped[8][1] >= .8" :src="require('../../assets/icons8-hedgehog-100.svg')" height="100"/>
        <img v-else :src="require('../../assets/arctic-fox.svg')" height="100"/>
        </v-col>
        
        <v-col cols="8" sm="7" align="start">
        <p v-if="rewrapped[8][0][0][1] / rewrapped[8][1] >= .8">Based on where you'd publish,<br> it looks like you study one big thing!</p>
        <p v-else>Based on where you'd publish,<br> it looks like you study many things!</p>
        </v-col>
      </v-row>
      </div>
      </Transition>
      
      <Transition>
      <div id="concept_barchart" v-show="panels[2]['value'] > 35">
      </div>
      </Transition>


    </div>

    <div v-show="panel == 3">
      <v-row align="start" class="mt-2">
        <v-col cols="12" class="pb-0"><h3>Your Venues Ranked Based On...</h3></v-col>
      <v-col cols="6" align="center">
        <Transition>
          <div v-if="panels[3]['value'] > 5">
          <p class="pb-2"><b>Your Comparisons</b></p>

        <TransitionGroup name="list" tag="ol" class="pl-8 text-left">

          <li :value=journal[0] v-for="journal in userOrdered.slice(0,
                                                                    Math.max(0,
                                                                    Math.min(Math.floor(this.panels[3]['value']/3)-3,
                                                                    this.simWidth >= 800 ? 10 : 5)))" :key="journal">
            {{ journal[1] }}
          </li>
        
        </TransitionGroup>
        </div>
        </Transition>
      </v-col>
      
      <v-col cols="6" align="center">
        <Transition>
          <div v-if="panels[3]['value'] > 46">
        <p class="pb-2"><b>All Comparisons</b></p>

        <TransitionGroup name="list" tag="ol" class="pl-8 text-left">
        <li class="" :value=journal[0] v-for="journal in overallOrdered.slice(0,
                                                                              Math.max(0,
                                                                              Math.min(Math.floor(this.panels[3]['value']/3)-16,
                                                                              this.simWidth >= 800 ? 10 : 5)))" :key="journal">
          {{ journal[1] }}
        </li>
        </TransitionGroup>
        </div>
        </Transition>
      </v-col>
    </v-row>

    </div>

    <div v-show="panel >= 4">
        
      <v-row align="start" class="">
        <v-col :cols="sumCols" align="start" class="pl-6 mt-n0 pb-0" style="text-align:left">
          <v-btn
                    color="blue-grey-darken-2"
                    class="mt-n0 ml-12"
                    icon=""
                    size="24px"
                    style="z-index: 1"
                    @click="restart(); this.scroll()"
                >
                <v-icon icon="mdi-video" size="12px"></v-icon>
                
              </v-btn>
          <v-row align="center" no-gutters>
          <v-col cols="2" align="start">
            <img style="display: block;" v-if="rewrapped[8][0][0][1] / rewrapped[8][1] >= .8" :src="require('../../assets/icons8-hedgehog-100.svg')" :height="staticIconSize"/>
            <img style="display: block;" v-else :src="require('../../assets/arctic-fox.svg')" :height="staticIconSize"/>
          </v-col>
          <v-col cols="10" align="start" class="pl-4" style="text-align:left">
            <p class="pb-0">
              <b>{{rewrapped[0][0].toLocaleString() + formatPlacement(rewrapped[0][0])}}</b> of <b>{{(rewrapped[0][1]+1).toLocaleString()}}</b> participants
            </p>
          </v-col>
          </v-row>

          <v-row align="center" no-gutters>
          <v-col cols="2" align="start">
            <v-icon :color="colors[0][1]" v-if="rewrapped[1][0]/rewrapped[0][1] > .5" icon="mdi-telescope" :size="staticIconSize"></v-icon>
            <v-icon :color="colors[0][2]" v-else icon="mdi-microscope" :size="staticIconSize"></v-icon>
          </v-col>
          <v-col cols="10" align="start" class="pl-4" style="text-align:left">
            <p><b>{{rewrapped[1][1]}}</b> venues discovered</p> 
          </v-col>
          </v-row>

          <v-row align="center" no-gutters>
          <v-col cols="2" align="start">
            <v-icon :color="colors[2][1]" v-if="rewrapped[6][0][0]/rewrapped[6][0][1] > .5" icon="mdi-lock-open" :size="staticIconSize"></v-icon>
            <v-icon :color="colors[2][2]" v-else icon="mdi-lock" :size="staticIconSize"></v-icon>
          </v-col>
          <v-col cols="10" align="start" class="pl-4" style="text-align:left">
            <p class="pb-0">
              <b>{{parseInt(100*rewrapped[6][0][0]/rewrapped[6][0][1])}}%</b> Open Access
            </p>
          </v-col>
          </v-row>

          <v-row align="center" no-gutters>
          <v-col cols="2" align="start">
            <v-icon :color="colors[3][1]" v-if="rewrapped[7][0][0][0][1]/rewrapped[7][0][1] > .5" icon="mdi-home-circle" :size="staticIconSize"></v-icon>
            <v-icon :color="colors[3][2]" v-else icon="mdi-earth" :size="staticIconSize"></v-icon>
          </v-col>
          <v-col cols="10" align="start" class="pl-4" style="text-align:left">
            <p class="pb-0">
            <b>{{parseInt(100*rewrapped[7][0][0][0][1]/rewrapped[7][0][1])}}%</b> hosted in {{regionRename(regionNames.of(rewrapped[7][0][0][0][0]))}}
          </p>
          </v-col>
          </v-row>

          <div id="static_barchart">
          </div>

        </v-col>
        <v-col :cols="sumCols" align="start" class="pl-4 mb-6 topCol" style="text-align:left">
            <v-row align="center" no-gutters>
            <v-col cols="9" align="start" class="pl-0 mt-0">
              
              <v-card elevation="0" :height="staticIconSize" class="d-flex flex-column justify-center">
              
                <p class="mt-0 topTitle"><b>Top Ranked Venues</b></p>
              </v-card>
            </v-col>
            </v-row>
            <ol class="mt-0" id="topList" ref="topList">
              <li style="text-align:left" :value=journal[0] class = "mt-2" v-for="journal in userOrdered.slice(0,topLen)" :key="journal">
                {{ journal[1] }}
              </li>
            </ol>
        </v-col>
      </v-row>

      
    </div>

    <div id="summary" class="floatingPage">
        
      <v-row align="start" class="mt-0">
        <v-col cols="6" align="start" class="pl-6">

          <v-row align="center" no-gutters class="mb-1">
          <v-col cols="2" align="start">
            <img style="display: block;" :src="require('../../assets/q_logo.png')" :height="savedIconSize"/>
          </v-col>
          <v-col cols="10" align="start" class="pl-2">
            <p class="pb-0">
              ep<sup>3</sup>ic
            </p>
          </v-col>
          </v-row>

          <v-row align="center" no-gutters>
          <v-col cols="2" align="start">
            <img style="display: block;" v-if="rewrapped[8][0][0][1] / rewrapped[8][1] >= .8" :src="require('../../assets/icons8-hedgehog-100.svg')" :height="savedIconSize"/>
            <img style="display: block;" v-else :src="require('../../assets/arctic-fox.svg')" :height="savedIconSize"/>
            
          </v-col>
          <v-col cols="10" align="start" class="pl-2">
            <p class="pb-0">
              <b>{{rewrapped[0][0].toLocaleString() + formatPlacement(rewrapped[0][0])}}</b> of <b>{{rewrapped[0][1]+1}}</b> participants
            </p>
          </v-col>
          </v-row>

          <v-row align="center" no-gutters>
          <v-col cols="2" align="start">
            <v-icon :color="colors[0][1]" v-if="rewrapped[1][0]/rewrapped[0][1] > .5" icon="mdi-telescope" :size="savedIconSize"></v-icon>
            <v-icon :color="colors[0][2]" v-else icon="mdi-microscope" :size="savedIconSize"></v-icon>
          </v-col>
          <v-col cols="10" align="start" class="pl-2">
            <p><b>{{rewrapped[1][1]}}</b> venues discovered</p> 
          </v-col>
          </v-row>

          <v-row align="center" no-gutters>
          <v-col cols="2" align="start">
            <v-icon :color="colors[2][1]" v-if="rewrapped[6][0][0]/rewrapped[6][0][1] > .5" icon="mdi-lock-open" :size="savedIconSize"></v-icon>
            <v-icon :color="colors[2][2]" v-else icon="mdi-lock" :size="savedIconSize"></v-icon>
          </v-col>
          <v-col cols="10" align="start" class="pl-2">
            <p class="pb-0">
              <b>{{parseInt(100*rewrapped[6][0][0]/rewrapped[6][0][1])}}%</b> Open Access
            </p>
          </v-col>
          </v-row>

          <v-row align="center" no-gutters>
          <v-col cols="2" align="start">
            <v-icon :color="colors[3][1]" v-if="rewrapped[7][0][0][0][1]/rewrapped[7][0][1] > .5" icon="mdi-home-circle" :size="savedIconSize"></v-icon>
            <v-icon :color="colors[3][2]" v-else icon="mdi-earth" :size="savedIconSize"></v-icon>
          </v-col>
          <v-col cols="10" align="start" class="pl-2">
            <p class="pb-0">
            <b>{{parseInt(100*rewrapped[7][0][0][0][1]/rewrapped[7][0][1])}}%</b> hosted in {{regionRename(regionNames.of(rewrapped[7][0][0][0][0]))}}
          </p>
          </v-col>
          </v-row>

          <div id="saved_barchart">
          </div>

        </v-col>
        <v-col cols="6" align="start" class="pl-4">
           
            <v-row align="center" no-gutters>
            <v-col cols="11" align="start" class="pl-0">
                <p class="mt-0"><b>Top Ranked Venues</b></p>
            </v-col>
            
            </v-row>

            <ol class="mt-0">
              <li :value=journal[0] class = "mt-2" v-for="journal in userOrdered.slice(0,NtoSave)" :key="journal">
                {{ journal[1] }}
              </li>
            </ol>
        </v-col>
      </v-row>

      
    </div>
    

    <v-spacer></v-spacer>
    <div v-show="panel < 4" class="mt-4">
      <v-row no-gutters>
        <v-col align="end" class="" cols="12">
          <v-btn
              color="blue-grey-darken-2"
              class="mr-2 mt-n4"
              icon=""
              size="24px"
              @click="skip(-1)"
          >
          <v-icon icon="mdi-skip-previous" size="12px"></v-icon>
        </v-btn>

          <v-btn
              color="blue-grey-darken-2"
              class="mr-2 mt-n4"
              icon=""
              size="24px"
              @click="pausePlay()"
          >
          <v-icon :icon="isPaused ? 'mdi-play' : 'mdi-pause'" size="12px"></v-icon>
        </v-btn>

        <v-btn
              color="blue-grey-darken-2"
              class="mr-2 mt-n4"
              icon=""
              size="24px"
              @click="skip(1)"
          >
          <v-icon icon="mdi-skip-next" size="12px"></v-icon>
        </v-btn>
        
        <v-btn
              color="blue-grey-darken-2"
              class="mr-2 mt-n4"
              icon=""
              size="24px"
              @click="endExperience()"
          >
          <v-icon icon="mdi-stop" size="12px"></v-icon>

        </v-btn>
        </v-col>
      
      <v-col v-for="panel in panels" :key="panel.id" cols="3" class="px-1">
          <v-progress-linear :model-value="panel.value" color="blue-grey-darken-2"></v-progress-linear>
      </v-col>
      </v-row>
    </div>
    </v-card>
  </v-col>
  </v-row>
</template>


<script>
import * as d3 from 'd3';


export default {
    name: 'WrappedExperience',
    props: ["code", "wrapping", "ranking","overallRanking", "rewrapped", "playWrapped","simWidth"],
  data: () => ({
    show: false,
    panels: [0,1,2,3].map( x => Object.fromEntries([["id",x],
                                                      ["value",0],
                                                      ["delay",[12000,10000,8000,10000][x]]])),
    numberEndings: ['th','st','nd','rd','th','th','th','th','th','th'],
    panel: 3,
    intervalId: null,
    userOrdered: null,
    overallOrdered: null,
    staticIconSize: 50,
    savedIconSize: 30,
    pieRadius: 125,
    isPaused: false,
    topN: 0,
    NtoSave: 10,
    addOne: true,
    colors: [["orange", "orange-lighten-1", "orange-darken-1"],
             ["blue", "blue-lighten-1", "blue-darken-1"],
             ["blue-grey-darken-1", "purple-lighten-1", "purple-darken-1"],
             ["blue-grey-darken-1", "green-lighten-1", "green-darken-1"]],
    sumCols: "6",
    topLen: 10,
  }),
  created() {
    this.scroll()
    if (this.playWrapped){
      this.panel = -1
    }
    this.userOrdered = Object.values(this.ranking).filter(x => x['wins'].length > 0 || x['losses'].length > 0).map(x => [x['norder']+1,
                                                      x['name']]).sort(function(a, b) { return a[0] - b[0] })
    this.overallOrdered = Object.values(this.overallRanking).map(x => [x['norder']+1,
                                                      x['name']]).sort(function(a, b) { return a[0] - b[0] })
    this.regionNames = new Intl.DisplayNames(["en"], { type: "region" })
  },
  mounted() {
    this.barChart("#concept_barchart", 448*.8, 352*.85, this.rewrapped)
    this.barChart("#static_barchart", 448*.8, 352*.85, this.rewrapped)
    this.barChart("#saved_barchart", 448*.9, 352*.9, this.rewrapped)
    this.play()

    const sumSumCols = () => {
      const windowWidth = window.innerWidth
      this.sumCols = windowWidth >= 700 ? "6" : "12"
      this.topLen = windowWidth >= 700 ? 10 : 100
    }
    sumSumCols()
    window.addEventListener("resize", sumSumCols);

    },
  methods: {
    pSBC(p,c0,c1,l) {
      let r,g,b,P,f,t,h,i=parseInt,m=Math.round,a=typeof(c1)=="string";
      if(typeof(p)!="number"||p<-1||p>1||typeof(c0)!="string"||(c0[0]!='r'&&c0[0]!='#')||(c1&&!a))return null;
      if(!this.pSBCr)this.pSBCr=(d)=>{
          let n=d.length,x={};
          if(n>9){
              [r,g,b,a]=d=d.split(","),n=d.length;
              if(n<3||n>4)return null;
              x.r=i(r[3]=="a"?r.slice(5):r.slice(4)),x.g=i(g),x.b=i(b),x.a=a?parseFloat(a):-1
          }else{
              if(n==8||n==6||n<4)return null;
              if(n<6)d="#"+d[1]+d[1]+d[2]+d[2]+d[3]+d[3]+(n>4?d[4]+d[4]:"");
              d=i(d.slice(1),16);
              if(n==9||n==5)x.r=d>>24&255,x.g=d>>16&255,x.b=d>>8&255,x.a=m((d&255)/0.255)/1000;
              else x.r=d>>16,x.g=d>>8&255,x.b=d&255,x.a=-1
          }return x};
      h=c0.length>9,h=a?c1.length>9?true:c1=="c"?!h:false:h,f=this.pSBCr(c0),P=p<0,t=c1&&c1!="c"?this.pSBCr(c1):P?{r:0,g:0,b:0,a:-1}:{r:255,g:255,b:255,a:-1},p=P?p*-1:p,P=1-p;
      if(!f||!t)return null;
      if(l)r=m(P*f.r+p*t.r),g=m(P*f.g+p*t.g),b=m(P*f.b+p*t.b);
      else r=m((P*f.r**2+p*t.r**2)**0.5),g=m((P*f.g**2+p*t.g**2)**0.5),b=m((P*f.b**2+p*t.b**2)**0.5);
      a=f.a,t=t.a,f=a>=0||t>=0,a=f?a<0?t:t<0?a:a*P+t*p:0;
      if(h)return"rgb"+(f?"a(":"(")+r+","+g+","+b+(f?","+m(a*1000)/1000:"")+")";
      else return"#"+(4294967296+r*16777216+g*65536+b*256+(f?m(a*255):0)).toString(16).slice(1,f?undefined:-2)
  },
    pageChange (i) {
        this.$emit('page-change', i)
    },
    async restart(){
      this.isPaused = false
      this.addOne = true
      this.panels = [0,1,2,3].map( x => Object.fromEntries([["id",x],
                                                            ["value",0],
                                                            ["delay",[12000,10000,8000,10000][x]]]))
      this.panel = -1
      this.intervalId = null

      this.$emit('changeState', true)
      await new Promise(resolve => setTimeout(resolve, 1000));
      this.play()
    },
    endExperience(){
      this.panel = 4
      this.addOne = true
      clearInterval(this.intervalId)
      clearTimeout(this.timeoutId)
      this.$emit('changeState', false)
      this.$emit('endWrap')
      this.scroll()
    },
    play(){
      if (this.addOne){
        this.panel++
      }
      if (this.intervalId){
        clearInterval(this.intervalId)
      }
      this.addOne = true
      if (this.panel < 4){
        if (this.panels[this.panel]['value'] < 100){
          this.intervalId = setInterval(this.panelPlus, this.panels[this.panel]['delay']/100);
          this.timeoutId = setTimeout(this.play, (1 - (this.panels[this.panel]['value'])/100) * this.panels[this.panel]['delay'])
        }
      } else {
        this.endExperience()
      }
    },
    pausePlay(){
      if (this.panel == -1){
        return
      }
      if (this.isPaused){
        this.isPaused = false
        this.play()
      } else{
        this.addOne = false
        this.isPaused = true
        clearTimeout(this.timeoutId)
        clearInterval(this.intervalId)
      }
    },
    skip(i){
      if (this.panel == -1){
        return
      }
      this.addOne = true
      if (!this.isPaused){
        this.isPaused = true
        clearTimeout(this.timeoutId)
        clearInterval(this.intervalId)
      }
      if (i == -1){
        this.panels[this.panel]['value'] = 0
        if (this.panel > 0){
          this.panel --
        } else {
          this.addOne = false
        }
      } else {
      if (this.panels[this.panel]['value'] == 100 && this.panel < 3){ 
        this.panel ++
      }
      this.panels[this.panel]['value']  = 100
      
      }
      
    },
    panelPlus(){
        this.panels[this.panel]['value'] ++
        if (this.panels[this.panel]["id"] == 3 & this.panels[this.panel]['value'] % 5 == 0 & this.panels[this.panel]['value'] > 10){
          this.topN ++
        } 
    },
    regionRename(n){
      if (n == "United States"){
        return "The United States"
      } else if (n == "Namibia"){
        return "Unknown Countries"
      }
        else {
        return n
      }
    },
    formatPlacement(n){
      if (String(n).slice(-2) == "11" || String(n).slice(-2) == "13"){
        return "th"
      } else{
        return this.numberEndings[parseInt(String(this.numberCheck(n)).slice(-1))]
      }
    },
    numberCheck(n){
      return parseInt(String(n).slice(-2)) <= 10 ? n : parseInt(String(n).slice(-2)) <= 13 ? 0 : n
    },
    barChart(focal_id, w, h,version){
    var that = this
    var margin = {top: 30, right: 30, bottom: 70, left: 60},
        width = w - margin.left - margin.right,
        height = h - margin.top - margin.bottom;
    var svg = d3.select(focal_id)
      .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
      .append("g")
        .attr("transform",
              "translate(" + margin.left + "," + margin.top + ")");
    var x = d3.scaleBand()
      .range([ 0, width ])
      .domain(version[8][0].map(function(d) { return d[0]; }))
      .padding(0.2);
    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x))
      .selectAll("text")
        .attr("class", "x_ticks")
        .attr("text-anchor", "middle")
        .attr("transform", "translate(0,0)") 
        

    svg.append("text")
    .attr("class", "x label")
    .attr("text-anchor", "middle")
    .attr("x", width / 2)
    .attr("y", height + 42)
    .style("font-size", "12px")
    .text("Field");

    var y = d3.scaleLinear()
      .domain([0, Math.max(...version[8][0].map(x => x[1]))])
      .range([ height, 0]);

    svg.append("g")
      .call(d3.axisLeft(y).ticks(5).tickFormat(d3.format("d")));

    svg.append("text")
    .attr("class", "y label")
    .attr("text-anchor", "middle")
    .attr("y", -32)
    .attr("x", height / -2)
    .attr("transform", "rotate(-90)")
    .style("font-size", "12px")
    .text("# of Venues");

    svg.selectAll("mybar")
      .data(version[8][0])
      .enter()
      .append("rect")
        .attr("x", function(d) { return x(d[0]); })
        .attr("y", function(d) { return y(d[1]); })
        .attr("width", x.bandwidth())
        .attr("height", function(d) { return height - y(d[1]); })
        .attr("fill", d => that.pSBC(.4,d[2]))

    var insertLinebreaks = function (d) {
        var el = d3.select(this);
        var words = d.split(' ');
        el.text('');

        for (var i = 0; i < words.length; i++) {
            var tspan = el.append('tspan').text(words[i]).style("font-size", Math.min(12,w/version[8][0].length/8));
            if (i > 0)
                tspan.attr('x', 0).attr('dy', '10');
        }
    };

    svg.selectAll('.x_ticks').each(insertLinebreaks);
    },
    scroll() {
        window.scrollTo(0,0);
    },
  },
}
</script> 

<style>
.v-enter-active {
  transition: opacity 0.3s ease;
}
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.list-move, 
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.list-leave-active {
  position: absolute;
}

.progress-bars {
  position: absolute;
  bottom: 0;
}

.floatingPage { 
  position: absolute; 
  top      : -1000px; 
  width: 800px;
  overflow : hidden;
}

@media only screen and (max-width: 700px) {
  #topList {
    padding-left: 25px;
} .topTitle {
    padding-left: 25px;
} .topCol {
  margin-top:-30px
}
}
</style>