<template>
<div>
    <svg class="svg simulation"  :height="simHeight+110"
        :width="simWidth" transform="translate(0,0)">
        <g id="arcs">
        </g>
        <g id="venues">
        </g>
        <g id="subjects">
        </g>
        <defs>
        </defs>
    </svg>
    <rect id="svg_simulation" />
</div>
</template>

<script>
import * as d3 from 'd3';

export default {
    props: ['simHeight', 'simWidth', 'selected', "vizData", "colorDict", "groups", "nodeRadius", "flipped", "headerHeight","notClicked","stage","topJournals"],
    data: () =>({
        simulation: null,
        nodes: {},
        nodeValues: [], 
        numNodes: 0,
        Nrestarts: 0,
        lastSelected: -1,
        colors: [],
        colorCount: [],
        tooltip: null,
        justAdded: [],
        simStarted: true,
        paths: [],
        isUpdating: false,
        oldNodePos: {},
        lastHovered: null,
        viewChange: false,
    }),
    async created() {
        await this.startDiscoverSimulation()
        this.updateDiscoverSimulation(true)
    },
    unmounted() {
        this.simulation.stop()
    },
    watch: {
        async vizData(){ 
            await this.deleteNodes()
            this.updateDiscoverSimulation() 
        },
        async groups(){
            await this.deleteNodes()
            this.updateDiscoverSimulation(false)
        },
        async stage(){
            this.viewChange = false
            await this.deleteNodes()
            this.updateDiscoverSimulation(true)
        },
        async simWidth(){
            this.viewChange = true
            await this.updateDiscoverSimulation(true)
        },
        async notClicked(){
            await this.updateDiscoverSimulation(true)
            this.simStarted = true
        },
        selected(){ 
            var that = this
            d3.select('#venues')                                          
                .selectAll("path")
                .data(this.nodeValues, d => d.id)
                .join("path")
                .filter(function(d) { return (d.id  == that.selected['id']) || (d.id  == that.lastSelected)})
                    .attr("d", function(d){
                        return that.drawPath(d,d.id  == that.selected['id'] ? 1.2 : 1)
                    })
                    .style("fill-opacity",  function(d){if (d.fillOpacity >= 0)
                        {
                        return d.fillOpacity
                    } else{
                        return 0
                    }})
                    .style("stroke-opacity", function(d){if (d.fillOpacity >= 0){
                        return d.edgeOpacity
                    } else{
                        return 0
                    }})

            if (!this.selected.valid){
                d3.select('#venues')
                  .selectAll("path")
                  .data(this.nodeValues, d => d.id)
                  .join("path").interrupt().transition().duration(1000)
                  .style("fill-opacity",  function(d){if (d.fillOpacity >= 0){
                        return d.fillOpacity
                    } else{
                        return 0
                    }})
                    .style("stroke-opacity", function(d){if (d.fillOpacity >= 0){
                        return d.edgeOpacity
                    } else{
                        return 0
                    }})
            } else {
                d3.select('#venues')                                          
                    .selectAll("path")
                    .data(this.nodeValues, d => d.id)
                    .join("path")
                    .filter(function(d) { return (d.id  == that.selected['id'])}).interrupt()
                        .style("fill-opacity",  function(d){if (d.fillOpacity >= 0){
                        return d.fillOpacity
                    } else{
                        return 0
                    }})
                    .style("stroke-opacity", function(d){if (d.fillOpacity >= 0){
                        return 1
                    } else{
                        return 0
                    }})
            }
            that.lastSelected = that.selected['id']
        }
    },
    methods: {
        pSBC(p,c0,c1,l) {
            let r,g,b,P,f,t,h,i=parseInt,m=Math.round,a=typeof(c1)=="string";
            if(typeof(p)!="number"||p<-1||p>1||typeof(c0)!="string"||(c0[0]!='r'&&c0[0]!='#')||(c1&&!a))return null;
            if(!this.pSBCr)this.pSBCr=(d)=>{
                let n=d.length,x={};
                if(n>9){
                    [r,g,b,a]=d=d.split(","),n=d.length;
                    if(n<3||n>4)return null;
                    x.r=i(r[3]=="a"?r.slice(5):r.slice(4)),x.g=i(g),x.b=i(b),x.a=a?parseFloat(a):-1
                }else{
                    if(n==8||n==6||n<4)return null;
                    if(n<6)d="#"+d[1]+d[1]+d[2]+d[2]+d[3]+d[3]+(n>4?d[4]+d[4]:"");
                    d=i(d.slice(1),16);
                    if(n==9||n==5)x.r=d>>24&255,x.g=d>>16&255,x.b=d>>8&255,x.a=m((d&255)/0.255)/1000;
                    else x.r=d>>16,x.g=d>>8&255,x.b=d&255,x.a=-1
                }return x};
            h=c0.length>9,h=a?c1.length>9?true:c1=="c"?!h:false:h,f=this.pSBCr(c0),P=p<0,t=c1&&c1!="c"?this.pSBCr(c1):P?{r:0,g:0,b:0,a:-1}:{r:255,g:255,b:255,a:-1},p=P?p*-1:p,P=1-p;
            if(!f||!t)return null;
            if(l)r=m(P*f.r+p*t.r),g=m(P*f.g+p*t.g),b=m(P*f.b+p*t.b);
            else r=m((P*f.r**2+p*t.r**2)**0.5),g=m((P*f.g**2+p*t.g**2)**0.5),b=m((P*f.b**2+p*t.b**2)**0.5);
            a=f.a,t=t.a,f=a>=0||t>=0,a=f?a<0?t:t<0?a:a*P+t*p:0;
            if(h)return"rgb"+(f?"a(":"(")+r+","+g+","+b+(f?","+m(a*1000)/1000:"")+")";
            else return"#"+(4294967296+r*16777216+g*65536+b*256+(f?m(a*255):0)).toString(16).slice(1,f?undefined:-2)
        },

        drawHexagon(radius, rotation=0) {
            var thirdPi = Math.PI / 3,
            angles = [0, thirdPi, 2 * thirdPi, 3 * thirdPi, 4 * thirdPi, 5 * thirdPi].map(x => x + rotation*thirdPi);
            return angles.map(function(angle) {
            var x1 = Math.sin(angle) * radius,
                y1 = -Math.cos(angle) * radius
            return [x1, y1];
            });
        },  
        drawPath(d, boost = 1){
            let radius = boost * d.r[Math.min(this.stage,2)]
            if (d.k == 'kk'){
                let sr = radius*.625
                return " m -"+sr+", 0 a "+sr+","+sr+" 0 1,0 "+2*sr+",0 a "+sr+","+sr+" 0 1,0 -"+2*sr+",0" + "M0,0"+ " m -"+radius+", 0 a "+radius+","+radius+" 0 1,0 "+2*radius+",0 a "+radius+","+radius+" 0 1,0 -"+2*radius+",0"

            } else if (d.k[0] == 'k'){

                return " m -"+radius+", 0 a "+radius+","+radius+" 0 1,0 "+2*radius+",0 a "+radius+","+radius+" 0 1,0 -"+2*radius+",0"

            } else if (d.k[1] == 'k'){

                return " m -"+radius+", 0 a "+radius+","+radius+" 0 1,0 "+2*radius+",0 a "+radius+","+radius+" 0 1,0 -"+2*radius+",0"

            } else if (d.k[0] == 'c'){
                return " m -"+radius+", 0 a "+radius+","+radius+" 0 1,0 "+2*radius+",0 a "+radius+","+radius+" 0 1,0 -"+2*radius+",0"

            } else {
                return " m -"+radius+", 0 a "+radius+","+radius+" 0 1,0 "+2*radius+",0 a "+radius+","+radius+" 0 1,0 -"+2*radius+",0"
            }

            
        },
        deleteNodes(){
            Object.entries(this.nodes).forEach(([key, value]) => {
                this.oldNodePos[key] = {"x":value.x,"y":value.y}
            });

            var newNodes = []
            const stage_loc = [16,17,18][Math.min(this.stage,2)]
            const top_ids = this.topJournals.map(x => x[5])
            for (const journals of Object.values(this.vizData)) {
                for (let i = 0; i < journals.length; i++) {
                    if (journals[i][stage_loc] > 0 || top_ids.includes(journals[i][5])){
                    newNodes.push(journals[i][0].toString())
                    }
                }
            }
            for (const k of Object.keys(this.nodes)) {
                if (!newNodes.includes(k)){
                    this.numNodes--
                    delete this.nodes[k]
                }
            }
            this.nodeValues = Object.values(this.nodes)
        },
        readTop(journals){
            var nJournals = journals.length
            var topXY = {}
            this.paths = []
            for (let i = 0; i < nJournals; i++) {
                const journal = journals[i]
                const r = this.nodeRadius*8 
                const x = this.simWidth*(this.flipped ? journal[1] : journal[0])
                const y = 1.2*this.headerHeight + (this.simHeight-1.2*this.headerHeight)*(this.flipped ? journal[0] : journal[1])

                topXY[journal[4]] = [x,y]
                if (i > 0){
                    this.paths.push([i-1,i,
                                        topXY[this.topJournals[i-1][4]][0],
                                        topXY[this.topJournals[i-1][4]][1],
                                        x,y,r,
                                        "n" + this.topJournals[i-1][5],
                                        "n" + this.topJournals[i][5]]
                                    )
                }
                if (journal[7]){
                    this.nodes["n" + journal[5]] = {'id':"n"+journal[5], 
                    'name':journal[4],
                    'vx':0, 'vy':0, 'tx': x, 'ty': y,
                    'x': x,
                    'y': y,
                    'k': "ku", 'color':journal[3],'concept':journal[6],
                    'r': [r,r,r,r],
                    'rx': [r,r,r,r],
                    'fillOpacity':.2, 'edgeOpacity':.9,'edgeColor':"black",
                    'hovered':false}
                    this.numNodes ++
                }
            }

        },
        readJournals(journals, k){
            var oDict = {"kk":.45,"kc":.45,"ku":.5,"ck":0,"uk":.3,"cc":.25, "cu":.3,"uc":.1,"uu":.1}
            var eDict = {"kk":.85,"kc":.85,"ku":.9,"ck":.85,"uk":.4,"cc":.0, "cu":0,"uc":.1,"uu":.1}
            var nJournals = journals.length
            var topNames = this.topJournals.map(x => x[4])
            const stage_loc = [16,17,18][Math.min(this.stage,2)]
            if (k == 'cc'){
                nJournals = Math.max(0, journals.length)
            }
            for (let i = 0; i < nJournals; i++) {
                const journal = journals[i]

                const bonus =  topNames.includes(journal[1]) ? 8 : 0

                const r = 1.2*this.nodeRadius * 1.2**(Math.log(journal[6] + bonus))
                const r1 = bonus > 0 ? this.nodeRadius*bonus : this.nodeRadius*(.5 + Math.log(journal[stage_loc]))
                const r_old = stage_loc == 16 ? r1 : bonus > 0 ? this.nodeRadius*bonus : this.nodeRadius*(.5 + Math.log(journal[stage_loc-1]))
                const r2 = this.nodeRadius*(.5 + Math.log(journal[stage_loc]+.1))
                
                const pull = .8
                const pull2 = .9
                const x2 = r + (this.flipped ? 1 - journal[2] : journal[3])*(pull2*this.simWidth - 2*r) + (1-pull2)*.5*this.simWidth
                const yex = (this.simHeight - 1*this.headerHeight)
                const y2 = 1.*this.headerHeight + r + (this.flipped ? journal[3] : 1 - journal[2])*(pull*yex - 2*r) + (1-pull)*.5*yex - 20
                const tx = this.simWidth*(this.flipped ? journal[8] : journal[7])
                const ty = 1.2*this.headerHeight + (this.simHeight-1.2*this.headerHeight)*(this.flipped ? journal[7] : journal[8])

                const j_id = "n"+journal[0]
                const x = j_id in this.oldNodePos ? this.oldNodePos[j_id].x : tx
                const y = j_id in this.oldNodePos ? this.oldNodePos[j_id].y : ty
                if ("n"+journal[0] in this.nodes){
                    if (!this.justAdded.includes(journal[0])){             
                    this.nodes["n"+journal[0]]['k'] = k
                    this.nodes["n"+journal[0]]['r'] = [r1,r1,r1,r/1.2]
                    this.nodes["n"+journal[0]]['rx'] = [r1,r1,Math.max(r1,r_old),r/1.2]
                    this.nodes["n"+journal[0]]['fillOpacity'] = bonus > 0 ? .2 : oDict[k]
                    this.nodes["n"+journal[0]]['edgeOpacity'] = eDict[k]
                    this.nodes["n"+journal[0]]['tx'] = this.viewChange ? tx : this.nodes[journal[0]].x
                    this.nodes["n"+journal[0]]['ty'] = this.viewChange ? ty : this.nodes[journal[0]].y
                    this.nodes["n"+journal[0]]['edgeColor'] = bonus == 0 ? journal[4] : "black"
                    }
                }
                else if ((journal[stage_loc]) > 0 || (bonus > 0)){
                    this.nodes["n"+journal[0]] = {'id':"n"+journal[0], 'name':journal[1],
                'vx':0, 'vy':0, 'tx': tx, 'ty': ty,
                'x': this.stage > 0 ? x : x2,
                'y': this.stage > 0 ? y : y2,
                'k': k, 'color':journal[4],'concept':journal[5],'edgeColor':bonus == 0 ? journal[4] : "black",
                'r': [r1,r1,r1,r],'rx': [r1,r1,Math.max(r1,r_old),r],
                'fillOpacity':bonus > 0 ? .2 : oDict[k], 'edgeOpacity':eDict[k],
                'hovered':false}
                this.numNodes ++
                }
                if (bonus > 0 && r2 > 0){
                    this.nodes["n"+journal[0]+"_"] = {'id':"n"+journal[0] +"_", 'name':journal[1],
                'vx':0, 'vy':0, 'tx': tx, 'ty': ty,
                'x': this.stage > 0 ? x : x2,
                'y': this.stage > 0 ? y : y2,
                'k': k, 'color':journal[4],'concept':journal[5],'edgeColor': journal[4] ,
                'r': [r2,r2,r2,r],'rx': [0,0,0,0],
                'fillOpacity':oDict[k], 'edgeOpacity':eDict[k],
                'hovered':false}
                this.numNodes ++
                    
                }
                if (!this.groups.includes(k) && !this.justAdded.includes(journal[0])){
                    this.nodes[journal[0]]['fillOpacity'] = 0
                    this.nodes[journal[0]]['edgeOpacity'] = 0
                } else {
                    this.justAdded.push(journal[0])
                }
                if (!(this.colorCount.includes(journal[4]))){
                    this.colorCount.push(journal[4])
                    this.colors.push(['kk', journal[4], 1,1,0])
                    this.colors.push(['ku', journal[4], 1,1,1])
                    this.colors.push(['uk', journal[4], 1,1,1])
                    this.colors.push(['kc', journal[4], 1,1,1])
                    this.colors.push(['ck', journal[4], 1,1,1])
                    this.colors.push(['cu', journal[4], 1,1,1])
                    this.colors.push(['cc', journal[4], 1,1,1])
                    this.colors.push(['uu', journal[4], 1,1,1])
                    this.colors.push(['uc', journal[4], 1,1,1])
                }
            }
        },
        async addNodes(){
            this.justAdded = []                                                              
            for (const k of ['ku']){
                this.readJournals(this.vizData[k], k)
            }
            this.readTop(this.topJournals)
            this.nodeValues = Object.values(this.nodes)
        },

        async startDiscoverSimulation() {
            var that = this

            this.simulation = d3.forceSimulation()
                            .force("x", d3.forceX(
                                function (d) {return d.tx }
                            ).strength(.05)) 
                            .force("y", d3.forceY(
                                function (d) {return d.ty }
                            ).strength(.05))
                            .force("collide", d3.forceCollide().strength(.1)
                                    .radius(function(d) {return that.isUpdating ? 1.3*d.rx[2]: 1.3*d.rx[0]}).iterations(1))

            this.simulation.on("tick", function(){             
                        d3.select('#venues').selectAll('path')
                        .attr('transform', function(d) {
                                const d_id = String(d.id)
                                if (d_id.slice(-1) == "_"){
                                    const other = d3.select("path#"+ d_id.slice(0,-1))
                                    d.x = other._groups[0][0].__data__.x
                                    d.y = other._groups[0][0].__data__.y
                                }
                                else{
                                    let borderX = 1.2*d.r[Math.min(that.stage,2)]
                                    let borderY = 1.2*d.r[Math.min(that.stage,2)]
                                    if (d.fillOpacity != 0){
                                        let dx = d.x < that.simWidth-borderX ? Math.max(borderX,d.x) : Math.min(that.simWidth-borderX,d.x); d.x = dx
                                        let dy = d.y < that.simHeight-borderY ? Math.max(borderY,d.y) : Math.min(that.simHeight-borderY,d.y); d.y = dy
                                    }
                                }
                                return "translate("+d.x+','+d.y+')'
                              })

                        d3.select('#arcs').selectAll("path")
                            .attr("d", function(d){
                                const a = d3.select("path#"+ d[7])._groups[0][0].__data__
                                const b = d3.select("path#"+ d[8])._groups[0][0].__data__
                                var sx = a.x
                                var sy = a.y
                                var ex = b.x
                                var ey = b.y

                                const tx = b.x - a.x
                                const ty = b.y - a.y
                                const dist = Math.sqrt(tx**2 + ty**2)

                                sx += tx*(d[6]/dist)
                                sy += ty*(d[6]/dist)

                                ex -= tx*(d[6]/dist)
                                ey -= ty*(d[6]/dist)

                                return "M"+sx+
                                        ","+sy+
                                        "Q"+((sx+ex)/2)+","+((sy+ey)/2)+","+
                                        ex+","+ey
                            }) 
                        });
            
        },

        clickDiscoverNode(n){
            let d = this.nodes[n.id]; 
            if (d.fillOpacity > .25 && this.lastHovered != d.id.slice(0,-1)){
                this.lastHovered = n.id
                this.$emit('clicked', {'id': d.id, 'name':d.name, 'valid':true})
            }
        },

        async updateDiscoverSimulation (restart = true){
            await this.addNodes()
            var that = this

            let grads = d3.select('defs').selectAll("radialGradient")
                .data(this.colors)
                .join("radialGradient")
                    .attr('id', function(d){return d[0] +d[1].replace(/[^a-zA-Z\d]/gm,"")})

            d3.select('defs')
                .append('marker')
                .attr('id', 'arrow')
                .attr('viewBox', [0, 0, 30, 30])
                .attr('refX', 0)
                .attr('refY', 0)
                .attr("style", "overflow: visible")
                .attr("x",0)
                .attr("y",0)
                .attr("width",10)
                .attr("height",10)
                .attr('markerWidth', 6*that.nodeRadius)
                .attr('markerHeight', 6*that.nodeRadius)
                .attr('orient', 'auto-start-reverse')
                .attr("markerUnits","userSpaceOnUse")
                .append('path')
                .attr("x",0)
                .attr("y",0)
                .attr("width",'100%')
                .attr("height",'100%')
                .attr('d',"M -0,-0 L-10,-6 M 0,0 L-10,6 ") 
                .style("opacity",1)
                .style("stroke-width", "2px")
                .attr('stroke', 'black')
                .attr("fill",'black');

            grads.append("stop").attr("offset", "0%")
                .style("stop-color", (d) => d[4] == 0 ? "white" : d[1])
                .style("stop-opacity",  d => d[2])
            grads.append("stop").attr("offset", "55%")
                .style("stop-color", (d) => d[4] == 0 ? "white" : d[1])
                .style("stop-opacity",  d => d[2])
            grads.append("stop").attr("offset", "65%")
                .style("stop-color", (d) => d[3] == 0 ? "white" : d[1])
                .style("stop-opacity",  d => d[2])

            let subject_positions = {'Medicine': [-181.5, 4],
                'Biology': [-181.5, 3],
                'Environmental science': [-181.5, 2],
                'Geology': [-181.5, 0],
                'Chemistry': [-181.5, 1],
                'Physics': [-115, 0],
                'Materials science': [-96, 1],
                'Engineering': [-53, 0],
                'Geography': [2, 2],
                'Mathematics': [146.3, 0],
                'Computer science': [45, 0],
                'Business': [116.5, 1],
                'Economics': [103.2, 2],
                'Political science': [65, 3],
                'History': [131, 4],
                'Art': [95, 4],
                'Philosophy': [2, 4],
                'Sociology': [-20, 3],
                'Psychology': [-95, 4],
                'Unknown': [-120, 3],
                }

            this.isUpdating = true
            if (restart && that.stage != 0){
                this.simulation.nodes(this.nodeValues).alpha(1).restart()
            } 

            let colorMap = this.colorDict.map(a => [a[0],a[1], subject_positions[a[0]].map(function(x) { return x })])
            d3.select('#subjects')
                .selectAll("text")
                .data(that.notClicked ? [] : colorMap, d => d[0])
                .join("text")
                    .attr("y", d => that.simHeight + 10 + d[2][1]*18 + (that.notClicked ? that.headerHeight : 0))
                    .attr("text-anchor", "middle")
                        .each(function(d) {
                            var arr = d[0] == 'Computer science' ? d[0].replace("science","Science").split(" ") : [d[0].replace("science","Science").replace("Mathematics","Math")]
                            d3.select(this).selectAll("tspan")
                            .data(arr)
                            .join("tspan")
                            .attr("text-anchor", "start")
                            .attr("x", d[2][0] + that.simWidth/2)
                            .attr("dy", function() {
                                return "1.125em"
                            })
                            .text(String)
                        })
                    .style("fill", (d) => that.pSBC(.1,d[1]))
                    .on("mouseover", function() {
                        d3.selectAll('.default').style("visibility", "hidden")
                        let concept = d3.select(this)._groups[0][0].__data__[0]
                        d3.select('#subjects').selectAll("text").filter(function(){ 
                            return d3.select(this).text().toLowerCase().replace("math","mathematics") != (concept.toLowerCase() == "computer science" ? concept.toLowerCase().replace(/\s/g, '') : concept.toLowerCase())
                        }).interrupt().transition().duration(750)
                        .style("font-weight", 100)
                        d3.select('#venues')                                          
                            .selectAll("path")
                            .data(that.nodeValues, d => d.id)
                            .join("path").interrupt().transition().duration(750)
                            .style("fill-opacity",  function(d){return d.concept  == concept ? .7 : d.fillOpacity == 0 ? 0 : Math.min(.15,d.fillOpacity)})
                            .style("stroke-opacity", function(d){return d.concept  == concept ? d.fillOpacity == 0 ? 1 : 0 : d.fillOpacity == 0 ? 1 : 0})
                                
                            })
                            .on("mouseout", function() {
                                let concept = d3.select(this)._groups[0][0].__data__[0]
                                d3.select('#subjects').selectAll("text").filter(function(){ 
                                    return d3.select(this).text().toLowerCase().replace("math","mathematics") != (concept.toLowerCase() == "computer science" ? concept.toLowerCase().replace(/\s/g, '') : concept.toLowerCase())
                                }).interrupt().transition().duration(750)
                                .style("font-weight", 500)
                                d3.select('#venues')                                          
                                    .selectAll("path")
                                    .data(that.nodeValues, d => d.id)
                                    .join("path").interrupt().transition().duration(750)
                                        .style("fill-opacity",  function(d){return d.fillOpacity})
                                        .style("stroke-opacity", function(d){return d.edgeOpacity})
                            })
            this.tooltip = d3.select("#svg_simulation")
                .style("width","150px")
                .style("text-align","left")
                .style("position", "absolute")
                .style("visibility", "hidden")
                .style("background","#B0BEC5")
                .style("fill-opacity", .1)
                .style("padding", "4px")
                .style("border-radius", "8px")

            const nodeSelection = d3.select('#venues')                          
                .selectAll("path")
                .data(this.nodeValues, d => d.id)
                .attr("transform", d => "translate("+d.x+","+d.y+")")

            const newNodes = nodeSelection.enter().append("path")
                .attr("transform", d => "translate("+d.x+","+d.y+")")
                .attr("d", function(d){
                    return that.drawPath(d)
                })
                .attr("id",function(d){return d.id})
                .style("fill", function(d){return d.k == "ku" ? d.color : "none"})
                .style("fill-opacity", 0)
                .style("stroke", function(d){return d.edgeColor})
                .style("stroke-width", function(d){return ['uk','ck'].includes(d.k) ? .8 : .8})
                .style("stroke-opacity",0)
                .style("visibility", "visible")

                .on("mouseover", function(d) {
                    if (!that.isUpdating){
                        d3.selectAll('.default').style("visibility", "hidden")
                        let c = d3.select(this)._groups[0][0].__data__
                        if (c.fillOpacity > 0 && that.simStarted == true){
                            c.hovered = true
                            that.clickDiscoverNode(this)
                            d3.select('#subjects').selectAll("text").filter(function(){ 
                                return d3.select(this).text().toLowerCase().replace("math","mathematics") != (c.concept.toLowerCase() == "computer science" ? c.concept.toLowerCase().replace(/\s/g, '') : c.concept.toLowerCase())
                            }).interrupt().transition().duration(10)
                            .style("font-weight", 100)
                            that.lastHovered = c.id
                            that.tooltip.text(d.srcElement.__data__.name)	
                            let th = that.tooltip._groups[0][0].clientHeight
                            let tw = that.tooltip._groups[0][0].clientWidth
                            const width = that.flipped ? that.simHeight : that.simWidth
                            const height = that.flipped ? that.simWidth : that.simHeight
                            return that.tooltip
                                .style("height",th+"px")
                                .style("top", c.y <= height/2 ? (c.y+2.*c.r[Math.min(that.stage,2)])+"px" : (c.y - 2.*c.r[Math.min(that.stage,2)]-th)+"px")
                                .style("left",c.x <= width/2 ? c.x+c.r[Math.min(that.stage,2)]+"px" : c.x-tw+"px")
                                .style("background",that.pSBC(.42,c.color)) 
                                .style("border","solid" )
                                .style("border-color", that.pSBC(.0,c.color))
                                .style("border-width","1px")
                                .style("visibility", "visible")	
                                .style("text-align",c.x <= width/2 ? "center" : "center")
                        }
                    }
                })					
                .on("mouseout", function() {
                    if (!that.isUpdating){
                        let c = d3.select(this)._groups[0][0].__data__
                            d3.select('#subjects').selectAll("text").filter(function(){ 
                                    return d3.select(this).text().toLowerCase().replace("math","mathematics") != (c.concept.toLowerCase() == "computer science" ? c.concept.toLowerCase().replace(/\s/g, '') : c.concept.toLowerCase())
                                }).interrupt().transition().duration(200)
                                        .style("font-weight", 500)
                            that.$emit('clicked', {"name": "", "valid": false})	
                            return that.tooltip.style("visibility", "hidden").style("height",null)
                    }
                })
                    nodeSelection.exit()
                    .transition()
                    .duration(1000)
                    .style("fill-opacity", 0)
                    .style("stroke-opacity",0)
                    .remove()
                    .end()
                    nodeSelection.transition()
                            .duration(that.stage == 0 ? 0 : 2000)
                            .style("visibility", "visible")
                            .style("fill-opacity",  function(d){return d.fillOpacity})
                            .style("stroke-opacity", function(d){return d.edgeOpacity})
                            .style("stroke", function(d){return d.edgeColor})
                            .attr("d", function(d){
                                return that.drawPath(d)
                            })
                        .end()
                        .then(() => {
                            newNodes.transition()
                            .duration(1000)
                            .style("visibility", "visible")
                            .style("fill-opacity",  function(d){return d.fillOpacity})
                            .style("stroke-opacity", function(d){return d.edgeOpacity})
                            .style("stroke", function(d){return d.edgeColor})
                            .attr("d", function(d){
                                return that.drawPath(d)
                            })
                            .end()
                            .then(()=>{
                                d3.select('#arcs')
                                    .selectAll("path")
                                    .data(this.paths)
                                    .join("path")
                                    .attr("id", function(d){return 'a'+d[0]+'b'+d[1]})
                                    .style("fill", "none")
                                    .style("stroke-width", "1.2px")
                                    .style("stroke", "black")
                                    .style("opacity",1) 
                                    .attr('marker-end', 'url(#arrow)')

                                setTimeout(() => {
                                    this.isUpdating = false
                                    if (restart){
                                        this.simulation.nodes(this.nodeValues).alpha(1).restart()
                                    }
                                },
                                that.stage == 0 ? 0 : 0
                                )
                            }).catch(() => {
                            });
                        }).catch(() => {
                        });            
        },
    },
}
</script>