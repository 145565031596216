<template>
<div>
    
    <svg  :height="simHeight" 
        :width="simWidth" transform="translate(0,0)">
        <g id="nodes">
        </g>
        <defs>
        </defs>
    </svg>
    <div id="svg_simulation"></div>
</div>
</template>

<script>
import * as d3 from 'd3';

export default {
    props: ['simHeight', 'simWidth', 'knowns', 'unknowns', 'nClicked', 'nActions', 'selected', 'preferences'],
    data: () =>({
        simulation: null,
        tooltip: null,
        nodes: {},
        nodeValues: [], 
        nodeRadius: 10,
        circleRadius: 80,
        numNodes: 0,
        Nrestarts: 0,
        colorCount: [],
        colors: [],
        compared: null,
    }),
    async created() {
        await this.startDiscoverSimulation()
        this.compared = [... new Set([].concat(this.preferences.map(x => [x[0],x[1]]).flat(2)))]
        if (this.nClicked >= 3) {this.updateDiscoverSimulation()}
    },
    unmounted() {
        this.simulation.stop()
    },
    watch: {
        async nActions(){ 
            if (this.nClicked >= 3) {await this.updateDiscoverSimulation()}},
        async selected() {var that = this
                    d3.select('#nodes')                          
                        .selectAll("circle")
                        .data(this.nodeValues, d => d.id)
                        .join("circle")
                        .style("fill", function(d){return d.color})
                        .style("fill-opacity",  function(d){return d.fillOpacity})
                        .attr("r", function(d){return d.id == that.selected['id'] ? 1.2*d.r : d.r})
        },
        async simWidth(){
            await this.startDiscoverSimulation()
            this.compared = [... new Set([].concat(this.preferences.map(x => [x[0],x[1]]).flat(2)))]
            if (this.nClicked >= 3) {this.updateDiscoverSimulation()}
        }
    },
    methods: {
        pSBC(p,c0,c1,l) {
            let r,g,b,P,f,t,h,i=parseInt,m=Math.round,a=typeof(c1)=="string";
            if(typeof(p)!="number"||p<-1||p>1||typeof(c0)!="string"||(c0[0]!='r'&&c0[0]!='#')||(c1&&!a))return null;
            if(!this.pSBCr)this.pSBCr=(d)=>{
                let n=d.length,x={};
                if(n>9){
                    [r,g,b,a]=d=d.split(","),n=d.length;
                    if(n<3||n>4)return null;
                    x.r=i(r[3]=="a"?r.slice(5):r.slice(4)),x.g=i(g),x.b=i(b),x.a=a?parseFloat(a):-1
                }else{
                    if(n==8||n==6||n<4)return null;
                    if(n<6)d="#"+d[1]+d[1]+d[2]+d[2]+d[3]+d[3]+(n>4?d[4]+d[4]:"");
                    d=i(d.slice(1),16);
                    if(n==9||n==5)x.r=d>>24&255,x.g=d>>16&255,x.b=d>>8&255,x.a=m((d&255)/0.255)/1000;
                    else x.r=d>>16,x.g=d>>8&255,x.b=d&255,x.a=-1
                }return x};
            h=c0.length>9,h=a?c1.length>9?true:c1=="c"?!h:false:h,f=this.pSBCr(c0),P=p<0,t=c1&&c1!="c"?this.pSBCr(c1):P?{r:0,g:0,b:0,a:-1}:{r:255,g:255,b:255,a:-1},p=P?p*-1:p,P=1-p;
            if(!f||!t)return null;
            if(l)r=m(P*f.r+p*t.r),g=m(P*f.g+p*t.g),b=m(P*f.b+p*t.b);
            else r=m((P*f.r**2+p*t.r**2)**0.5),g=m((P*f.g**2+p*t.g**2)**0.5),b=m((P*f.b**2+p*t.b**2)**0.5);
            a=f.a,t=t.a,f=a>=0||t>=0,a=f?a<0?t:t<0?a:a*P+t*p:0;
            if(h)return"rgb"+(f?"a(":"(")+r+","+g+","+b+(f?","+m(a*1000)/1000:"")+")";
            else return"#"+(4294967296+r*16777216+g*65536+b*256+(f?m(a*255):0)).toString(16).slice(1,f?undefined:-2)
        },
        readJournals(journals, k){
            for (let i = 0; i < journals.length; i++) {
                const journal = journals[i]
                if (journal[0] in this.nodes){
                    this.nodes[journal[0]]['k'] = k == 0 ? "k" : k == 1 ? "u" : "c"
                    this.nodes[journal[0]]['fillOpacity'] = k == 0 ? .45 : k == 1 ? 0 : .45,
                    this.nodes[journal[0]]['edgeOpacity'] = k == 0 ? .85 : k == 1 ? .85 : 0,
                    this.nodes[journal[0]]['r'] = this.nodeRadius * (k == 0 ? .75 : k == 1 ? .5 : 1.8)
                    this.nodes[journal[0]]['color'] = journal[4]

                    const x = 20 + (journal[3])*(this.simWidth-40)
                    this.nodes[journal[0]]['tx'] = x
                } else {
                    const x = 20 + (journal[3])*(this.simWidth-40)
                    const y = 20 + (1-journal[2])*(this.simHeight-40)
                    this.nodes[journal[0]] = {'id':journal[0], 'name':journal[1],
                    'vx':0, 'vy':0, 'tx': x, 'ty': y,
                    'x': .4 * (this.simWidth/2 - 20) + .6 * (x),
                    'y': .4 * (this.simHeight/2 - 20) + .6 * (y),
                    'fillOpacity': k == 0 ? .45 : k == 1 ? 0 : .45,
                    'edgeOpacity': k == 0 ? .85 : k == 1 ? .85 : 0,
                    'r': this.nodeRadius * (k == 0 ? .75 : k == 1 ? .5 : 1.8),
                    'k': k == 0 ? "k" : k == 1 ? "u" : "c", 'color':journal[4], 'n': this.numNodes}
                    this.numNodes ++
                }
                if (!(this.colorCount.includes(journal[4]))){
                    this.colorCount.push(journal[4])
                    this.colors.push(['k', journal[4], 1,1,1])
                    this.colors.push(['u', journal[4], 0,0,1])
                    this.colors.push(['c', journal[4], .2,.2,.2])
                }
            }
        },
        async addNodes(){
            this.circleRadius = 1*Math.min(this.simWidth, this.simHeight) / 2
            this.nodeRadius =  16
                                                                                     
            for (const [k, values] of Object.entries([this.knowns, 
                                                      this.unknowns])) {
                    this.readJournals(values, k)                                 }
            this.nodeValues = Object.values(this.nodes)
        },

        async startDiscoverSimulation() {
            var that = this
            
            this.simulation = d3.forceSimulation().velocityDecay(0.6)
                            .force("x", d3.forceX(
                                function (d) {return .95*d.tx + .05*that.simWidth/2}
                            ).strength(.08)) 
                            .force("y", d3.forceY(
                                function (d) {return .95*d.ty + .05*that.simHeight/2}
                            ).strength(.08))
                            .force("collide", d3.forceCollide().strength(1)
                                    .radius(d => 1.2*d.r).iterations(3))

            this.simulation.on("tick", function(){
                        d3.select('#nodes').selectAll('circle')
                            .attr("cx", function(d){ let dx = d.x < that.simWidth-2*d.r ? Math.max(2*d.r,d.x) : Math.min(that.simWidth-2*d.r,d.x); d.x = dx; return d.x })
                            .attr("cy", function(d){ let dy = d.y < that.simHeight-2*d.r ? Math.max(2*d.r,d.y) : Math.min(that.simHeight-2*d.r,d.y); d.y = dy; return d.y })
                        });
        },

        clickDiscoverNode(n){
            let d = this.nodes[n.id]; 
            if (!this.compared.includes(d.id)){
                this.$emit('update:selected', {'id': d.id, 'name':d.name+"?", 'valid':true})
            }

        },

        async updateDiscoverSimulation (){
            await this.addNodes()

            var that = this
            this.tooltip = d3.select("#svg_simulation")
                .style("width","150px")
                .style("text-align","left")
                .style("position", "absolute")
                .style("visibility", "hidden")
                .style("background","#B0BEC5")
                .style("opacity", .9)
                .style("padding", "4px")
                .style("border-radius", "8px")

            d3.select('#nodes')                          
                        .selectAll("circle")
                        .data(this.nodeValues, d => d.id)
                        .join("circle")
                        .attr("id",function(d){return d.id})
                        .attr("cx", function(d){ return d.x})
                        .attr("cy", function(d){ return d.y})
                        .attr("r", function(d){ return d.r})
                        .style("fill", function(d){return d.color})
                        .style("fill-opacity",  function(d){return d.fillOpacity})

                        .style("stroke", function(d){return d.id == that.selected['id'] ? "black" : d.color})
                        .style("stroke-width", function(d){return d.id == that.selected['id'] ? 2 : .8})
                        .style("stroke-opacity", function(d){return d.id == that.selected['id'] ? 1 : d.edgeOpacity})
                        .on("mouseover", function(d) {
                        d3.select(this).attr("r", function(d){ return 1.2*d.r})
                        let c = d3.select(this)._groups[0][0].__data__
                        that.tooltip.text(d.srcElement.__data__.name)	
                        let th = that.tooltip._groups[0][0].clientHeight
                        that.$emit('update:hovering', d.srcElement.__data__.name)
                        return that.tooltip
                            .style("height",th+"px")
                            .style("top", c.y <= that.simHeight/2 ? (c.y+0*c.r)+"px" : (c.y-1.2*c.r - th + 24)+"px")
                            .style("left",c.x <= that.simWidth/2 ? (c.x+1.2*c.r)+"px" : (c.x-1.2*c.r-150)+"px")		
                            .style("background",c.k === "u" ? that.pSBC(.42,"rgb(252, 252, 252)") : that.pSBC(.42,c.color))
                            .style("border","solid" )
                            .style("border-color", that.pSBC(.0,c.color))
                            .style("border-width","1px")
                            .style("visibility", "visible")	
                            .style("text-align",c.x <= that.simWidth/2 ? "center" : "center")
                        })					
                        .on("mouseout", function() {	
                            that.$emit('update:hovering', "")
                            d3.select(this).attr("r", function(d){ return d.r})
                            return that.tooltip.style("visibility", "hidden").style("height",null) 
                        })

            this.simulation.nodes(this.nodeValues).alpha(that.Nrestarts == 0 ? 1 : 0.2).restart()
            this.Nrestarts ++
        },
    },
}
</script>

<style scoped>
div.tooltip {	
    position: absolute;			
    text-align: center;			
    width: 60px;					
    height: 28px;					
    padding: 2px;				
    font: 12px sans-serif;		
    background: lightsteelblue;	
    border: 1px;
    border-width: 1px;		
    border-radius: 8px;			
    pointer-events: none;			
}
</style>